import {Component, OnInit} from '@angular/core';
import {NavbarService} from '../config/navbar.service';
import {User} from '../models/user';
import {UserService} from '../config/user.service';
//import {AuthService} from 'angularx-social-login';
import {WishlistService} from '../config/wishlist.service';
import {CartService} from '../config/cart.service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DialogComponent} from '../dialog/dialog.component';
import {NgForm} from '@angular/forms';
import {ConfigSettings} from '../config/config.settings';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SupportDetails} from '../config/support-details';
import {CookieService} from "ngx-cookie-service";

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  navigationStatus: boolean = true;
  appStoreLink = '';
  playStoreLink = '';
  facebookLink = '';
  twitterLink = '';
  instagramLink = '';
  snapchatLink = '';
  user: User;
  email: string;
  validEmail: boolean = false;
  lang: string;
  mailChimpEndpoint = 'https://furnishop-web.us20.list-manage.com/subscribe/post-json?u=f3074b58b0bb806778ba8ce4b&id=8842f54562&';
  mailchimpSubmitted = false;
  support_email = '';
  support_phone = '';
  termsAgreed: boolean = false;
  emptyMessage: boolean = false;
  btnClicked: boolean = false;
  constructor(
    private navbarService: NavbarService,
    protected localStorage: LocalStorage,
    private userService: UserService,
    // private authService: AuthService,
    private wishlistService: WishlistService,
    private cartService: CartService,
    private dialog: MatDialog,
    private configSettings: ConfigSettings,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
  }

  ngOnInit() {
    this.lang = this.configSettings.getLang();
    let terms_agreed = this.cookieService.get('terms-agreed');

    if(terms_agreed == '1') {
      this.termsAgreed = true;
    }

    /*this.navbarService.change.subscribe(status => {
        this.navigationStatus = status;
    });*/
    this.userService.getUserSession().then(response => {
      if (response !== false) {
        this.user = response;
      }
    });
    this.localStorage.getItem<any>('support_details').subscribe((result) => {
      if (result) {
        const SupportDetails = <SupportDetails>result;
        this.support_email = SupportDetails.support_email;
        this.support_phone = SupportDetails.support_phone;
      }
    });
  }

  showLogoutModal() {
    let dialogRef = this.dialog.open(DialogComponent, {
      data: {logout: true, user: this.user},
    });
  }

  onSubmit(emailForm: NgForm) {
    console.log(this.email);
    this.btnClicked = true;
    if (this.email && this.validEmail) {
      this.registerForNewsletter();
      this.btnClicked = false;
      emailForm.reset();
    }
  }

  registerForNewsletter() {
    this.configSettings.toggleLoading(true);
    const params = new HttpParams()
      .set('NAME', '')
      .set('EMAIL', this.email)
      .set('b_123abc123abc123abc123abc123abc123abc', '');

    const mailChimpUrl = this.mailChimpEndpoint + params.toString();

    this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(
      response => {
        this.configSettings.toggleLoading(false);
        this.mailchimpSubmitted = true;
        setTimeout(() => {
          this.mailchimpSubmitted = false;
        }, 15000);
      }, error => {
        this.configSettings.toggleLoading(false);
      });
  }

  onChange(newValue) {
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validEmailRegEx.test(newValue)) {
      this.validEmail = true;
    } else {
      this.emptyMessage = true;
      setTimeout(() => {
          this.emptyMessage = false;
        }, 15000);
      this.validEmail = false;
    }
  }

  agreeCookieTerms() {
    this.cookieService.set('terms-agreed', '1');
    this.termsAgreed = true;
  }
}
