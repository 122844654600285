  import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Banner} from '../models/banner';
import {Notification} from '../models/notification';
import {Title} from '@angular/platform-browser';
import {ConfigService} from '../config/config.service';
import {User} from '../models/user';
import {Category} from '../models/category';
import {Product} from '../models/product';
import {ConfigSettings} from '../config/config.settings';
import {NotificationService} from '../config/notification.service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Store} from '../models/store';
import {FooterService} from '../config/footer.service';
import {UserService} from '../config/user.service';
import {PopularSearchService} from '../config/popular-search.service';
import {Suggestion} from '../models/suggestion';
import {Collections} from '../models/collections';
import {NavbarService} from '../config/navbar.service';
import {Shops} from '../models/Shops';
import {Brands} from '../models/brands';
import {ServiceProvider} from '../models/service-provider';
import {CollectionGroups} from '../models/collection-groups';
import {Collection} from '../models/collection';
import {Router, NavigationEnd} from '@angular/router';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material';
import {WishlistService} from '../config/wishlist.service';
import {CartService} from '../config/cart.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  wishlist_items_count = 0;
  cart_items_count = 0;
  viewType: number;
  top_category: any;
  is_rental = 0;
  shop_banner_website = '';
  rent_banner_website = '';
  pro_banner_website = '';
  shop_banner_mobile = '';
  rent_banner_mobile = '';
  pro_banner_mobile = '';
  product_section_title = '';
  product_section_subtitle = '';

  banners: Banner[] = [];
  brands: Brands[] = [];
  serviceProvider: ServiceProvider[] = [];
  notifications: Notification[] = [];
  page = 1;
  pageSize = 20;
  totalPages: number;
  totalProducts: number;
  popularSearch: Suggestion[] = [];
  user: User = new User();
  categories: Category[] = [];
  categoriesXs: Category[] = []; // For small devices
  featuredProducts: Product[] = [];
  bestSellers: Product[] = [];
  newArrivals: Product[] = [];
  shops: Shops[] = [];
  website_header_text: string;
  lang: string;
  currency: string;
  store: Store;
  dataAvailable = false;
  slideConfig: any = {
    variableWidth: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 2,
    dots: true,
    prevArrow: '<span class="slick-arrow slick-prev"><i class="fas fa-chevron-left"></i></span>',
    nextArrow: '<span class="slick-arrow slick-next"><i class="fas fa-chevron-right"></i></span>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  slideConfigMob: any = {
    variableWidth: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    prevArrow: '<span class="slick-arrow slick-prev"><i class="fas fa-chevron-left"></i></span>',
    nextArrow: '<span class="slick-arrow slick-next"><i class="fas fa-chevron-right"></i></span>',
    mobileFirst:true,
  };
  proSlideConfig: any = {
    variableWidth: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    prevArrow: '<span class="slick-arrow slick-prev"><i class="fas fa-chevron-left"></i></span>',
    nextArrow: '<span class="slick-arrow slick-next"><i class="fas fa-chevron-right"></i></span>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  shopSlideConfig: any = {
    variableWidth: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 3,
    dots: false,
    prevArrow: '<span class="slick-arrow slick-prev"><i class="fas fa-chevron-left"></i></span>',
    nextArrow: '<span class="slick-arrow slick-next"><i class="fas fa-chevron-right"></i></span>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };
  top_banner_collections: Collections[] = [];
  bottom_banner_collections: Collections[] = [];
  // left_cover_collection: Collections;
  left_collections: Collections[] = [];
  // left_full_block_collection: Collections[];
  right_collections: Collections[] = [];
  horizontal_collections: Collections[] = [];
  collectionGroups: CollectionGroups[] = [];
  instagramShops: CollectionGroups[] = [];
  magazine: CollectionGroups[] = [];
  homeBannerSlideConfig: any = {
    variableWidth: false,
    slidesToShow: 1,
    infinite: false,
    dots: true,
    slidesToScroll: 1,
    autoplay: true,
    rtl: (this.lang == 'ar') ? true : false,
    prevArrow: '<span class="slick-arrow slick-prev"><i class="fas fa-chevron-left"></i></span>',
    nextArrow: '<span class="slick-arrow slick-next"><i class="fas fa-chevron-right"></i></span>',
  };


  constructor(
    private titleService: Title,
    private configService: ConfigService,
    private notificationService: NotificationService,
    private popularSearchService: PopularSearchService,
    protected localStorage: LocalStorage,
    protected footerService: FooterService,
    private userService: UserService,
    private navbarService: NavbarService,
    public configSettings: ConfigSettings,
    private router: Router,
    private dialog: MatDialog,
    private wishlistService: WishlistService,
    private cartService: CartService
  ) {
    this.lang = this.configSettings.getLang();
    /*if(this.lang == 'ar'){
      this.homeBannerSlideConfig.rtl = true;
    }*/
  }

  ngOnInit() {
    this.navbarService.currentTopCategory.subscribe((result) => {
      if (result) {
        this.top_category = result;
        this.configSettings.toggleLoading(true);
        this.readHomeApi();
      }
    });
    this.top_category = window.localStorage.getItem('top-category');
    
    /* checking if top category is selected by user */
    if (!this.top_category) {
      this.setTopCategory('shop');
    }

    this.viewType = 2;
    this.notificationService.currentNotification.subscribe(data => this.notifications = data);
    this.lang = this.configSettings.getLang();

    /**
     * getting store from config
     *  */
    this.configSettings.getStoreObject().then((result) => {
      this.configSettings.toggleLoading(true);
      this.store = <Store>result;
      this.currency = result['currency_' + this.lang];
      /**
       * Getting user session data
       */
      this.userService.getUserSession().then(response => {
        if (response !== false) {
          this.user = response;
        }
        if (this.viewType === 2) {
          this.readHomeApi();
        } else {
          this.configSettings.toggleLoading(false);
        }
      }, error => {
        this.configSettings.toggleLoading(false);
      });
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      //window.scrollTo(0, 0);
      //Scroll to
    });
  }

  ngAfterViewInit() {
    /*if (!this.configSettings.getNewsletter()) {
      const confirmMessage = (this.lang === 'en') ? 'Be the first to know about new arrivals, interior design trends, and the latest professionals.' : 'كن أول من يعرف عن الوافدين الجدد واتجاهات التصميم الداخلي وأحدث المتخصصين.';

      const dialogRef = this.dialog.open(DialogComponent, {
        data: {newsletter: true, message: confirmMessage},
      });
    }*/
  }

  readHomeApi() {
    this.is_rental = (this.top_category === 'shop') ? 0 : 1;
    try {
      const params = {
        'store': this.store.iso_code,
        'user_id': this.user.id,
        'lang': this.lang,
        'page': this.page,
        'per_page': this.pageSize,
        'is_rental': this.is_rental
      };

      this.configService.readRequest('website-home', params)
        .subscribe(response => {
            if (response.status === 200) {
              this.banners = [];
              this.serviceProvider = [];
              this.featuredProducts = [];
              response.body.data.banners.map(banner => {
                this.banners.push(banner);
              });
              response.body.data.brands.map(brand => {
                this.brands.push(brand);
              });
              response.body.data.pro_list.map(pro => {
                this.serviceProvider.push(pro);
              });
              response.body.data.new_products.map(newArrivals => this.newArrivals.push(newArrivals));
              response.body.data.featured_products.map(featured => this.featuredProducts.push(featured));
              response.body.data.best_seller_products.map(bestSellers => this.bestSellers.push(bestSellers));
              this.notifications.push({title: response.body.data.notify_text, sub_title: ''});

              // this.collectionGroups = response.body.data.collectionGroups;
              response.body.data.shops.map(shops => this.shops.push(shops));
              this.instagramShops = response.body.data.instagram_shops;
              console.log(this.instagramShops);
              this.magazine = response.body.data.magazine;
              this.categories = response.body.data.home_categories;
              this.product_section_title = response.body.data.product_section_title;
              this.product_section_subtitle = response.body.data.product_section_subtitle;

              /**** Passing notification from home to header component and adding in local storage ****/
              this.notificationService.changeNotification(this.notifications);
              this.localStorage.setItem('notifications', this.notifications).subscribe(() => {
              });

              var supportDetails = [];
              supportDetails['support_phone'] = response.body.data.support_phone;
              supportDetails['support_email'] = response.body.data.support_email;
              this.navbarService.setSupportDetails(supportDetails);

              if (response.body.data.wishlist_items_count !== 0) {
                this.wishlist_items_count = response.body.data.wishlist_items_count;
                this.wishlistService.changWishlistCount(this.wishlist_items_count);
              }

              if (response.body.data.cart_items_count !== 0) {
                this.cart_items_count = response.body.data.cart_items_count;
                this.cartService.changCartCount(this.cart_items_count);
              }

              this.totalPages = response.body.data.total_pages;
              this.totalProducts = response.body.data.total_products;
              this.configSettings.toggleLoading(false);
              this.viewType = 2;
              this.dataAvailable = true;

              setTimeout(() => {
                this.footerService.toggleFooter(true);
              }, 300);
            } else {
              this.configSettings.toggleLoading(false);
            }
          },
          error => {
            this.configSettings.toggleLoading(false);
          },
        );
    } catch (e) {
      console.error(e);
      this.configSettings.toggleLoading(false);
    }
  }

  readHomeBanners() {
    try {
      this.configService.readRequest('website-banner', {})
        .subscribe(response => {
            if (response.status === 200) {
              this.shop_banner_website = response.body.data.shop_banner_website;
              this.rent_banner_website = response.body.data.rent_banner_website;
              this.pro_banner_website = response.body.data.pro_banner_website;
              this.shop_banner_mobile = response.body.data.shop_banner_mobile;
              this.rent_banner_mobile = response.body.data.rent_banner_mobile;
              this.pro_banner_mobile = response.body.data.pro_banner_mobile;

              this.configSettings.toggleLoading(false);
              this.viewType = 1;
              this.dataAvailable = true;

              setTimeout(() => {
                this.footerService.toggleFooter(true);
              }, 300);
            } else {
              this.configSettings.toggleLoading(false);
            }
          },
          error => {
            this.configSettings.toggleLoading(false);
          },
        );
    } catch (e) {
      console.error(e);
      this.configSettings.toggleLoading(false);
    }
  }

  goToPage($event) {
    console.log($event);
    this.configSettings.toggleLoading(true);
    this.page = $event;
    this.readHomeApi();
    return false;
  }

  getBannerRoute(item: Collection) {
    if (item.type == 'RC' || item.type == 'SC' || item.type == 'PC') {
      return '/product/category/' + item.type_id;
    } else if (item.type == 'RP' || item.type == 'SP' || item.type == 'PS') {
      return '/product/detail/' + item.type_id;
    } else if (item.type == 'SN') {
      return '/product/shop/new-arrivals';
    } else if (item.type == 'RN') {
      return '/product/rent/new-arrivals';
    } else if (item.type == 'RB') {
      return '/product/rent/best-sellers';
    } else if (item.type == 'SB') {
      return '/product/shop/best-sellers';
    } else if (item.type == 'RF') {
      return '/product/rent/featured';
    } else if (item.type == 'SF') {
      return '/product/shop/featured';
    } else if (item.type == 'BR') {
      return '/brand/' + item.type_id;
    } else {
      return item.link;
    }
  }

  getUrlRoute(banner: Banner) {
    if (banner.link_type == 'RC' || banner.link_type == 'SC' || banner.link_type == 'PC') {
      return '/product/category/' + banner.name.toLowerCase() + '/' + banner.link_id;
    } else if (banner.link_type == 'RP' || banner.link_type == 'SP' || banner.link_type == 'PS') {
      return '/product/detail/' + banner.link_id;
    } else if (banner.link_type == 'SN') {
      return '/product/shop/new-arrivals';
    } else if (banner.link_type == 'RN') {
      return '/product/rent/new-arrivals';
    } else if (banner.link_type == 'RB') {
      return '/product/rent/best-sellers';
    } else if (banner.link_type == 'SB') {
      return '/product/shop/best-sellers';
    } else if (banner.link_type == 'RF') {
      return '/product/rent/featured';
    } else if (banner.link_type == 'SF') {
      return '/product/shop/featured';
    } else if (banner.link_type == 'BR') {
      return '/brand/' + banner.link_id;
    } else {
      return banner.url;
    }
  }

  setTopCategory(target: any) {
    this.navbarService.setTopCategory(target);
    window.location.reload();
  }

}
