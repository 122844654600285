import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Product} from '../models/product';
import {Store} from '../models/store';
import {ConfigSettings} from '../config/config.settings';
import {ConfigService} from '../config/config.service';
import {UserService} from '../config/user.service';
import {WishlistService} from '../config/wishlist.service';
import {first} from 'rxjs/operators';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CryptService} from '../config/crypt.service';
import {CartService} from '../config/cart.service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {reject} from 'q';
import {SaveToLookbookComponent} from '../save-to-lookbook/save-to-lookbook.component';
import {Router, NavigationEnd} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.css']
})

export class ProductItemComponent implements OnInit {
  @Input() item: Product;
  @Input() currency: string;
  @Input() enableRemoveWishlist: boolean = false;
  @Input() lang: string;
  @Input() store: Store;
  @Input() userId: number;
  @Input() returnUrl: string;
  @Input() minimal = false;
  @Input() showWishlistIcon = false;
  @Input() useHref = false;
  @Input() showOff = true;
  @Input() showShop = true;
  @Input() showAddCartIcon = false;
  @Input() showOutofStockIcon = false;
  @Input() routeLookbook = 0;
  in_wishlist = false;
  final_price: number;
  regular_price: number;
  off_percentage = 0;
  off_percentage_text: string;
  selectedAttribute: any[] = [];
  selectedOption: Number[] = [];
  wishlistCount: number = 0;
  @Output() parentFun = new EventEmitter();

  /*slideConfig: any = {
    variableWidth: false,
    slidesToShow: 1,
    infinite: true,
    dots: false,
    arrows: false,
    slidesToScroll: 1,
    autoplay: false,
    rtl: (this.lang === 'ar') ? true : false,
  };*/


  constructor(
    private configSettings: ConfigSettings,
    private configService: ConfigService,
    private userService: UserService,
    private wishlistService: WishlistService,
    private dialog: MatDialog,
    private cryptService: CryptService,
    private cartService: CartService,
    protected localStorage: LocalStorage,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.in_wishlist = (this.item.item_in_wishlist === 1) ? true : false;
    this.final_price = parseFloat(this.item.final_price);
    this.regular_price = parseFloat(this.item.regular_price);
    if (this.regular_price > this.final_price) {
      this.off_percentage = Math.round(100 - ((this.final_price / this.regular_price) * 100));
      this.off_percentage_text = (this.lang == 'en') ? this.off_percentage + '%' : '%' + this.off_percentage;
    }
  }

  toggleWishlist() {
    if (!this.userId) {
      if (this.returnUrl) {
        window.location.href = '/login?returnUrl=' + this.returnUrl;
      } else {
        window.location.href = '/login';
      }
      return false;
    }
    if (this.in_wishlist) {
      // remove from wishlist
      this.removeFromWishlist();
    } else {
      // add to wishlist
      this.addToWishlist();
    }
  }

  removeFromWishlist() {
    const confirmMessage = (this.lang === 'en') ? 'Do you wish to remove this item from wishlist?' : 'هل ترغب في إزالة هذا العنصر من قائمة الرغبات؟';

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {confirm: true, message: confirmMessage},
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('dialog data', data);
      if (data) {
        // this.configSettings.toggleLoading(true);
        const getParams = {
          lang: this.lang,
          store: this.store.iso_code
        };

        const postParams = {
          product_id: this.item.id,
          user_id: this.userId
        };

        this.wishlistService.removeFromWishlist(getParams, postParams)
          .pipe(first())
          .subscribe(
            response => {
              if (response.status === 200) {
                let items = response.data;
                this.userService.setWishlist(items);
                this.in_wishlist = !this.in_wishlist;
                this.parentFun.emit();
              }
              $('#remove-wishlist-' + this.item.id).remove();
              this.configSettings.toggleLoading(false);
            }
          );
      }
    });

    return false;
  }

  addToWishlist() {
    // this.configSettings.toggleLoading(true);
    const getParams = {
      lang: this.lang,
      store: this.store.iso_code
    };

    const postParams = {
      product_id: this.item.id,
      user_id: this.userId
    };

    this.wishlistService.addToWishlist(getParams, postParams)
      .pipe(first())
      .subscribe(
        response => {
          if (response.status === 200) {
            let items = response.data;
            this.userService.setWishlist(items);
            this.in_wishlist = !this.in_wishlist;
            console.log(this.in_wishlist);
          }

          //this.configSettings.toggleLoading(false);
        }
      );

    return false;
  }

  initAddToBag() {
    const getParams = {
      lang: this.lang,
      store: this.store.iso_code
    };

    const postParams = {
      product_id: this.item.id,
      option_id: (this.selectedAttribute.length) ? this.selectedAttribute.join(',') : '',
      attribute_id: (this.selectedOption.length) ? this.selectedOption.join(',') : ''
    };

    this.configSettings.toggleLoading(true);
    this.configService.postRequest('configurable-options', getParams, postParams)
      .subscribe(response => {
        console.log(response.data);
        console.log(this.item);
        if (response.data[0].attributes.length > 1) {
          let dialogRef = this.dialog.open(DialogComponent, {
            data: {item: this.item, moveWishlistToCart: true, attributes: response.data, store: this.store.iso_code, user_id: this.userId},
            maxWidth: 620
          });

        } else {
          this.addToBag();
        }

        this.configSettings.toggleLoading(false);
      }, error => {
        this.configSettings.toggleLoading(false);
      });
  }

  addToBag() {
    try {
      if (this.item.is_saleable) {
        if (this.item.id) {
          this.localStorage.getItem<any>('userInfo').subscribe((userInfo: string) => {
            if (userInfo) {
              this.addToOnlineCart(userInfo).then((any) => {
                this.configSettings.toggleLoading(false);
              }, error => {
                console.log('Error occured while adding product to cart!');
              });
            } else {

            }
          }, error => {
            this.configSettings.toggleLoading(false);
          });
        }
      }
    } catch (e) {
      this.configSettings.toggleLoading(false);
      console.log(e);
    }
  }

  private addToOnlineCart(user: string): Promise<any> {
    return new Promise(resolve => {
      this.cryptService.doDecrypt(user).then(decryptedString => {
        const user = JSON.parse(decryptedString);

        const getParams = {
          lang: this.lang,
          store: this.store
        };
        const postParams = {
          user_id: user.id,
          product_id: this.item.id,
          wishlist_product_id: this.item.id
        };

        this.configService.postRequest('move-wishlist-to-cart', getParams, postParams)
          .subscribe(response => {
            this.configSettings.setCartId(response.data.id);
            this.cartService.changCartCount(response.data.items.length);
            this.wishlistCount = parseInt(this.configSettings.getWishlistCount());
            if (this.wishlistCount > 0) {
              this.wishlistCount = this.wishlistCount - 1;
              this.wishlistService.changWishlistCount(this.wishlistCount);
            }
            this.parentFun.emit();
            this.configSettings.toggleLoading(false);
            resolve(true);
          }, error => {
            this.configSettings.toggleLoading(false);
          });

      });
    });
  }

  addToLookbook(event) {
    if (this.routeLookbook === 1) {
      event.preventDefault();

      const getParams = {
        lang: this.lang
      };
      const lookbook_id = window.localStorage.getItem('lookbook-id');
      const sketch_id = window.localStorage.getItem('sketch-id');

      if (lookbook_id != '0') {
        const postParams = {
          ideabook_id: lookbook_id,
          type: 'P',
          type_id: this.item.id.toString(),
          product_id: '',
          point_x: '',
          point_y: '',
          image_width: '',
          image_height: '',
          image: '',
          comments: '',
          user_id: this.userId
        };
        this.configSettings.toggleLoading(true);
        this.configService.postRequest('create-idea', getParams, postParams)
          .subscribe(response => {
            this.configSettings.toggleLoading(false);

            if (response.status == 200) {
              //show success
              this.router.navigate(['/lookbook/' + lookbook_id]);
            }
          }, error => {
            reject(error);
          });
      }
      if (sketch_id != '0') {
        let item: any[] = [];
        let existing_items = window.localStorage.getItem('sketch-product');
        if (existing_items != '') {
          item = JSON.parse(existing_items);
        }
        item.push({id: this.item.id, image: this.item.image});
        window.localStorage.setItem('sketch-product', JSON.stringify(item));
        this.router.navigate(['/sketch/' + sketch_id]);
      }
      return false;
    }
  }
}
