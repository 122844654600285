import { library} from '@fortawesome/fontawesome-svg-core';

import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faPinterestP } from '@fortawesome/free-brands-svg-icons/faPinterestP';
import { faGooglePlusG} from '@fortawesome/free-brands-svg-icons/faGooglePlusG';
import {faWhatsappSquare} from '@fortawesome/free-brands-svg-icons/faWhatsappSquare';
import {faCopy} from '@fortawesome/free-solid-svg-icons/faCopy';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import {faLink} from '@fortawesome/free-solid-svg-icons/faLink';
import {faEllipsisH} from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import {faMinus} from '@fortawesome/free-solid-svg-icons/faMinus';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';

const icons = [
    faFacebookF,
    faTwitter,
    faPinterestP,
    faGooglePlusG,
    faWhatsapp,
    faLink,
    faEllipsisH,
    faMinus,
    faCheck
];

library.add(...icons);
