import {Injectable} from '@angular/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {CryptService} from './crypt.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    protected localStorage: LocalStorage,
    protected cryptService: CryptService
  ) {
  }

  setRecentlyViewed(productsViewed: any): Promise<any> {
    //console.log('setRecentlyViewed');
    return new Promise(resolve => {
      this.cryptService.doEncrypt(JSON.stringify(productsViewed)).then(encryptedString => {
        this.localStorage.setItem('recentlyViewed', encryptedString).subscribe(() => {
          resolve(true);
        });
      });
    });
  }

  getRecentlyViewed(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.localStorage.getItem<string>('recentlyViewed').subscribe((recentlyViewed: string) => {
        if (recentlyViewed) {
          this.cryptService.doDecrypt(recentlyViewed).then(decryptedString => {
            const products = JSON.parse(decryptedString);
            resolve(products);
          });
        } else {
          resolve(false);
        }
      }, error => {
        reject(error);
      });
    });
  }

}
