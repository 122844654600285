import {Injectable} from '@angular/core';
import {CryptService} from './crypt.service';
import {Cart} from '../models/cart';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Attributes} from '../models/attributes';
import {Product} from '../models/product';
import {ConfigSettings} from './config.settings';
import {ConfigService} from './config.service';
import {Item} from '../models/item';
import {ConfigurableOptions} from '../models/configurable-options';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {strict} from 'assert';
import {DateFormatPipe} from './DateFormatPipe';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cartCountSource = new BehaviorSubject(0);
  currentcartCount = this.cartCountSource.asObservable();

  constructor(
    public cryptService: CryptService,
    protected localStorage: LocalStorage,
    private configSettings: ConfigSettings,
    private configService: ConfigService,
    private dateFormatPipe: DateFormatPipe
  ) {
    const cartCount = this.configSettings.getCartCount();
    this.changCartCount(cartCount);
  }

  changCartCount(count: any) {
    this.configSettings.setCartCount(count);
    this.cartCountSource.next(count);
  }

  addToOfflineCart(entityId: string, product: Product, configurableOptions: ConfigurableOptions[], quantity: number, start_date: string, end_date: string, rent_time: string): Promise<any> {
    return new Promise(resolve => {
      this.localStorage.getItem<string>('cart').subscribe((cart: string) => {
        if (cart) {
          this.cryptService.doDecrypt(cart).then(decryptedString => {
            let cartDetails: Cart = JSON.parse(decryptedString);
            if (cartDetails.items.length) {
              if (start_date) {
                start_date = this.dateFormatPipe.transform(new Date(start_date), 'yyyy-MM-dd');
              }else if( start_date == undefined){
                  start_date = '';
              }
              if (end_date) {
                end_date = this.dateFormatPipe.transform(new Date(end_date), 'yyyy-MM-dd');
              }
              /*console.log('start_date', this.dateFormatPipe.transform(new Date(start_date), 'yyyy-MM-dd'));
              return false;*/
              console.log(cartDetails.items);
              const index = cartDetails.items.findIndex(item => (item.id === entityId && item.is_rental === product.is_rental && item.start_date === start_date));
              
              console.log(entityId);console.log(product.is_rental);console.log(start_date);
              console.log(index);
              if (index === -1) {
                cartDetails.items.push({
                  order_item_id: cartDetails.items.length,
                  id: entityId,
                  parent_id: product.parent_id,
                  name: product.name,
                  brand_name: product.brand_name,
                  shop_name: product.shop_name,
                  short_description: product.short_description,
                  description: product.description,
                  SKU: product.SKU,
                  regular_price: product.regular_price,
                  final_price: product.final_price,
                  base_currency_id: 0,
                  currency_code: product.currency_code,
                  remaining_quantity: product.remaining_quantity,
                  minimum_quantity: product.minimum_quantity,
                  quantity: quantity,
                  is_featured: product.is_featured,
                  image: product.image,
                  configurable_option: configurableOptions,
                  is_saleable: product.is_saleable,
                  product_type: product.product_type,
                  is_rental: product.is_rental,
                  start_date: (start_date) ? start_date : '',
                  end_date: (end_date) ? end_date : '',
                  rent_time: (rent_time) ? rent_time : ''
                });
                // this.changCartCount(cartDetails.items.length);

                let cartTotalQty = 0;
                cartDetails.items.map((item) => {
                  cartTotalQty += item.quantity;
                });
                this.changCartCount(cartTotalQty);

                this.writeToStorage(cartDetails).then(response => {
                  resolve(true);
                });
              } else {
                    let updatedItemQuantity = cartDetails.items[index].quantity + quantity;
                cartDetails.items[index] = {
                  order_item_id: cartDetails.items[index].order_item_id,
                  id: entityId,
                  parent_id: product.parent_id,
                  name: product.name,
                  brand_name: product.brand_name,
                  shop_name: product.shop_name,
                  short_description: product.short_description,
                  description: product.description,
                  SKU: product.SKU,
                  regular_price: product.regular_price,
                  final_price: product.final_price,
                  base_currency_id: 0,
                  currency_code: product.currency_code,
                  remaining_quantity: product.remaining_quantity,
                  minimum_quantity: product.minimum_quantity,
                  quantity: updatedItemQuantity,
                  is_featured: product.is_featured,
                  image: product.image,
                  configurable_option: configurableOptions,
                  is_saleable: product.is_saleable,
                  product_type: product.product_type,
                  is_rental: product.is_rental,
                  start_date: (start_date) ? start_date : '',
                  end_date: (end_date) ? end_date : '',
                  rent_time: (rent_time) ? rent_time : ''
                };
                // this.changCartCount(cartDetails.items.length);

                let cartTotalQty = 0;
                cartDetails.items.map((item) => {
                  cartTotalQty += item.quantity;
                });
                this.changCartCount(cartTotalQty);

                this.writeToStorage(cartDetails).then(response => {
                  resolve(true);
                });
              }
            }
          });
        } else {
          product.parent_id = product.id;
          product.id = entityId;
          product.quantity = 1;
          let cart: Cart = {
            id: '',
            items: [{
              order_item_id: 0,
              id: product.id,
              parent_id: product.parent_id,
              name: product.name,
              brand_name: product.brand_name,
              shop_name: product.shop_name,
              short_description: product.short_description,
              description: product.description,
              SKU: product.SKU,
              regular_price: product.regular_price,
              final_price: product.final_price,
              base_currency_id: 0,
              currency_code: product.currency_code,
              remaining_quantity: product.remaining_quantity,
              minimum_quantity: product.minimum_quantity,
              quantity: quantity,
              is_featured: product.is_featured,
              image: product.image,
              configurable_option: configurableOptions,
              is_saleable: product.is_saleable,
              product_type: product.product_type,
              is_rental: product.is_rental,
              start_date: (start_date) ? this.dateFormatPipe.transform(new Date(start_date), 'yyyy-MM-dd') : '',
              end_date: (end_date) ? this.dateFormatPipe.transform(new Date(end_date), 'yyyy-MM-dd') : '',
              rent_time: (rent_time)? rent_time : ''
            }],
            vat_pct: 0,
            vat_charges: '',
            shipping_cost: ''
          };
          this.changCartCount(quantity);
          this.writeToStorage(cart).then(response => {
            resolve(true);
          });
        }
      });
    });
  }

  updateOfflineCart(entityId: string, product: Item, configurableOptions: ConfigurableOptions[], quantity: Number, start_date: string, end_date: string, rent_time: string): Promise<any> {
    return new Promise(resolve => {
      this.localStorage.getItem<string>('cart').subscribe((cart: string) => {
        if (cart) {
          this.cryptService.doDecrypt(cart).then(decryptedString => {
            let cartDetails: Cart = JSON.parse(decryptedString);
            if (cartDetails.items.length) {
              const index = cartDetails.items.findIndex(item => item.id === product.id);
              if (index !== -1) {
                cartDetails.items[index] = {
                  id: entityId,
                  parent_id: product.parent_id,
                  name: product.name,
                  brand_name: product.brand_name,
                  short_description: product.short_description,
                  description: product.description,
                  SKU: product.SKU,
                  regular_price: product.regular_price,
                  final_price: product.final_price,
                  base_currency_id: 0,
                  currency_code: product.currency_code,
                  remaining_quantity: product.remaining_quantity,
                  minimum_quantity: product.minimum_quantity,
                  quantity: 1,
                  is_featured: product.is_featured,
                  image: product.image,
                  configurable_option: configurableOptions,
                  is_saleable: product.is_saleable,
                  product_type: product.product_type,
                  is_rental: product.is_rental,
                  start_date: start_date,
                  end_date: end_date,
                  rent_time: rent_time
                };
                // this.changCartCount(cartDetails.items.length);
                let cartTotalQty = 0;
                cartDetails.items.map((item) => {
                  cartTotalQty += item.quantity;
                });
                this.changCartCount(cartTotalQty);
                this.writeToStorage(cartDetails).then(response => {
                  resolve(true);
                });
              } else {
                resolve(true);
              }
            }
          });
        }
      });
    });
  }

  writeToStorage(item: any): Promise<any> {
    return new Promise(resolve => {
      this.cryptService.doEncrypt(JSON.stringify(item)).then(encryptedString => {
        this.localStorage.setItem('cart', encryptedString).subscribe(() => {
          resolve(true);
        });
      });
    });
  }

  clearStorage(key) {
    let promise = new Promise<any>((resolve, reject) => {
      this.localStorage.removeItem(key).subscribe(() => {
        resolve(true);
      });
    });
    return promise;
  }

  getCartCount(): Promise<number> {
    let promise = new Promise<number>((resolve, reject) => {
      this.localStorage.getItem<string>('cart').subscribe((cart: string) => {
        if (cart) {
          this.cryptService.doDecrypt(cart).then(decryptedString => {
            let cartDetails: Cart[] = JSON.parse(decryptedString);
            resolve(cartDetails.length);
          });
        }
      });
    });

    return promise;

  }

  getCartValue(): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      this.localStorage.getItem<any>('cart').subscribe((cart: string) => {
        if (cart) {
          this.cryptService.doDecrypt(cart).then(decryptedString => {
            let cartDetails: Cart = JSON.parse(decryptedString);
            let cartValue: number = 0;

            cartDetails.items.map(item => {
              cartValue += (+item.quantity * +item.final_price);
            });

            if (cartValue) {
              resolve(cartValue);
            } else {
              resolve(false);
            }
          });
        }
      });
    });

    return promise;

  }

  getCartItems(): Promise<Cart> {
    let promise = new Promise<Cart>((resolve, reject) => {
      this.localStorage.getItem<any>('cart').subscribe((cart: string) => {
        let cartDetails: Cart = null;
        if (cart) {
          this.cryptService.doDecrypt(cart).then(decryptedString => {
            cartDetails = JSON.parse(decryptedString);
            // this.changCartCount(cartDetails.items.length);
            let cartTotalQty = 0;
            cartDetails.items.map((item) => {
              cartTotalQty += item.quantity;
            });
            this.changCartCount(cartTotalQty);
            resolve(cartDetails);
          });
        } else {
          resolve(cartDetails);
        }
      });
    });

    return promise;
  }

  readCartItems(user, lang, store): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      let cartDetails: Cart;
      const params = {
        user_id: user,
        lang: lang,
        store: store
      };
      this.configService.readRequest('cart-items', params)
        .subscribe(response => {
          this.configSettings.setCartId(response.body.data.id);
          cartDetails = response.body.data;
          // this.changCartCount(response.body.data.items.length);
          let cartTotalQty = 0;
          cartDetails.items.map((item) => {
            cartTotalQty += item.quantity;
          });
          this.changCartCount(cartTotalQty);
          resolve({cart: cartDetails, shipping: response.body.data.delivery_charges, vatCharges: response.body.data.vat_charges});
        }, error => {
          reject(error);
        });
    });

    return promise;
  }

  deleteCartItem(order_item_id): Promise<Cart> {
    let promise = new Promise<Cart>((resolve, reject) => {
      this.localStorage.getItem<any>('cart').subscribe((cart: string) => {
        if (cart) {
          this.cryptService.doDecrypt(cart).then(decryptedString => {
            let cartDetails: Cart = JSON.parse(decryptedString);

            cartDetails.items.map((item, index) => {
              if (item.order_item_id === order_item_id) {
                cartDetails.items.splice(index, 1);
              }
            });

            if (cartDetails.items.length) {
              this.writeToStorage(cartDetails).then(response => {
                resolve(cartDetails);
              });
            } else {
              this.clearStorage('cart').then(response => {
                resolve(cartDetails);
              });
            }
            // this.changCartCount(cartDetails.items.length);
            let cartTotalQty = 0;
            cartDetails.items.map((item) => {
              cartTotalQty += item.quantity;
            });
            this.changCartCount(cartTotalQty);
          });
        } else {
          resolve(null);
        }
      }, error => {
        reject(error);
      });
    });

    return promise;

  }

  updateQuantity(index, quantity): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      this.localStorage.getItem<any>('cart').subscribe((cart: string) => {
        if (cart) {
          this.cryptService.doDecrypt(cart).then(decryptedString => {
            let cartDetails: Cart = JSON.parse(decryptedString);
            let cartValue: number = 0;

            cartDetails.items[index].quantity = quantity;

            this.writeToStorage(cartDetails);

            cartDetails.items.map(item => {
              cartValue += (+item.quantity * +item.final_price);
            });
            // this.changCartCount(cartDetails.items.length);
            let cartTotalQty = 0;
            cartDetails.items.map((item) => {
              cartTotalQty += item.quantity;
            });
            this.changCartCount(cartTotalQty);
            if (cartValue) {
              resolve(cartDetails);
            } else {
              resolve(false);
            }
          });
        }
      });
    });

    return promise;
  }

  uploadOfflineCart(user): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      this.localStorage.getItem<any>('cart').subscribe((cart: string) => {
        if (cart) {
          this.cryptService.doDecrypt(cart).then(decryptedString => {
            let cartDetails: Cart = JSON.parse(decryptedString);

            if (cartDetails.items.length) {
              let product: Number[] = [];
              let quantity: Number[] = [];
              let startDate: String[] = [];
              let endDate: String[] = [];
              let tmp: string = "";
              let rentTime: String[] = [];

              cartDetails.items.map(item => {
                product.push(+item.id);
                quantity.push(item.quantity);

                if(item.is_rental === 1) {
                  tmp = (item.start_date) ? this.dateFormatPipe.transform(new Date(item.start_date), 'yyyy-MM-dd') : ''
                  startDate.push(tmp);

                  tmp = (item.end_date) ? this.dateFormatPipe.transform(new Date(item.end_date), 'yyyy-MM-dd') : ''
                  endDate.push(tmp);

                  tmp = (item.rent_time) ? item.rent_time : '';
                  rentTime.push(tmp);
                }
                else {
                  startDate.push("");
                  endDate.push("");
                  rentTime.push("");
                }
              });

              const getParams = {
                lang: this.configSettings.getLang(),
                store: this.configSettings.getStore()
              };
              const postParams = {
                user_id: user,
                products: product.join(','),
                quantity: quantity.join(','),
                start_date: startDate.join(','),
                end_date: endDate.join(','),
                rent_time: rentTime.join(','),

              };
              this.configService.postRequest('add-to-cart', getParams, postParams)
                .subscribe(response => {
                  this.configSettings.setCartId(response.data.id);
                  this.changCartCount(response.data.items.length);
                  resolve(true);
                });
            } else {
              resolve(true);
            }
          });
        } else {
          resolve(true);
        }
      });
    });

    return promise;
  }

  suggestions(filter: { q: string } = {q: ''}): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      const params = {
        q: filter.q
      };

      this.configService.readRequest('suggestions', params)
        .subscribe(response => {
          resolve(response);
        });
    });

    return promise;
  }

  search(filter: { q: string, search_type: string } = {q: '', search_type: ''}, page = 1): Observable<any> {
    // console.log('filter.search_type', filter.search_type);
    if (filter.search_type === 'shop') {
      filter.search_type = 'S';
    } else if (filter.search_type === 'rental') {
      filter.search_type = 'R';
    } else if (filter.search_type === 'find-a-pro') {
      filter.search_type = 'F';
    } else {
      filter.search_type = '';
    }
    const params = {
      q: filter.q,
      search_only: filter.search_type
    };
    return this.configService.readRequest('suggestions', params)
      .pipe(
        tap((response: any) => {
          return response.body.data;
        })
      );
  }
}
