import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {CRYPT_CONFIG_PROVIDER, CryptConfigProvider} from 'angular-encryption-service';
import {ConfigSettings} from './config/config.settings';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HomeModule} from "./home/home.module";
import {SharedModule} from "./shared/shared.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DialogModule} from "./dialog/dialog.module";
import {CookieService} from "ngx-cookie-service";

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HomeModule,
    DialogModule,
    AppRoutingModule
  ],
  exports: [],
  providers: [
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
