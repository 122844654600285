import {Component, Input, OnInit} from '@angular/core';
import {User} from '../models/user';
import {CryptService} from '../config/crypt.service';
import {CartService} from "../config/cart.service";
import {UserService} from "../config/user.service";
import {ConfigSettings} from "../config/config.settings";
import { WishlistService } from '../config/wishlist.service';
import {FormBuilder, FormGroup} from "@angular/forms";
import {debounceTime, finalize, switchMap, tap} from "rxjs/operators";
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material';
import {Store} from '../models/store';

@Component({
      selector: 'app-header-user-actions',
      templateUrl: './header-user-actions.component.html',
      styleUrls: ['./header-user-actions.component.css']
})
export class HeaderUserActionsComponent implements OnInit {
    userSession = new User();
    user: User;
    cartCount: number = 0;
    cartValue: string = '';
    wishlistCount: number = 0;
    userStatus = false;
    searchForm: FormGroup;
    isLoading = false;
    @Input() lang: string;

    constructor(
        private cartService: CartService,
        private wishlistService: WishlistService,
        private userService: UserService,
        private configSettings: ConfigSettings,
        private formBuilder: FormBuilder,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.checkUserSession();
        // this.cartCount = parseInt(this.configSettings.getCartCount());
        this.cartService.currentcartCount.subscribe(data => this.cartCount = data);
        this.wishlistService.currentWishlistCount.subscribe(data => this.wishlistCount = data);

        this.searchForm = this.formBuilder.group({
            q: null
        });

        /*this.searchForm.get('q').valueChanges.pipe(
            debounceTime(300),
            tap(() => this.isLoading = true),
            switchMap(value => this.cartService.search({q: value})
                .pipe(
                    finalize(() => this.isLoading = false),
                )
            )
        ).subscribe(results => {
            if (results.body.status === 200 && results.body.data.length) {
                this.suggestions = results.body.data;
            }
        });*/
    }

    checkUserSession() {
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                this.user = response;
                this.userSession.id = response.id;
                this.userSession.first_name = response.first_name;
                this.userSession.last_name = response.last_name;
                this.userSession.email = response.email;
                this.userStatus = true;
            }
        });
    }

    hideSearch(event: any) {
        document.querySelector('nav.header-nav').classList.remove('search-active');
        /* Do not hide  if user has clicked inside the dropdown */
        /*var element = document.getElementById('mat-autocomplete-1');
        if (element) {
            if(!element.contains(event.target)) {
                document.querySelector('nav.header-nav').classList.remove('search-active');
            }
        }*/
    }

    showLogoutModal() {
        let dialogRef = this.dialog.open(DialogComponent, {
            data: {logout: true, user: this.user },
        });
    }

}
