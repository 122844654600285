import {HttpHeaders} from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Store} from '../models/store';
import {catchError, map, mergeMap, retry} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import {CryptService} from './crypt.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigSettings {
  //
  //public static API_URL = 'http://websites.lezasolutions.com/furnishop/api/v1/';
  public static API_URL = 'https://admin.furni-shop.com/api/v1/';
  public static HTTP_OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  public static ENCRYPT_KEY = 'Furn2019';
  loading = false;
  currency: string;
  store: Store;
  @Output() load: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    protected localStorage: LocalStorage,
    protected cryptService: CryptService
  ) {
  }

  cleanUrl(url: string) {
    return url.trim().toLowerCase().replace(/[^a-zA-Z\d\s]/, '').replace(' ', '-');
  }

  getLang() {
    return window.localStorage.getItem('lang');
  }

  setLang(lang) {
    window.localStorage.setItem('lang', lang);
  }

  getNewsletter() {
    return window.localStorage.getItem('newsletter');
  }

  setNewsletter(data) {
    window.localStorage.setItem('newsletter', data);
  }

  getStore() {
    return window.localStorage.getItem('store');
  }

  setStore(store) {
    window.localStorage.setItem('store', store);
  }

  getStoreObject(): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      const storeObj = this.localStorage.getItem<any>('store_object').pipe(
        mergeMap(result => {
          if (!result) {
            return throwError('Error');
          }

          return of(result);
        }),
        retry(200)
      );
      storeObj.subscribe({
        next(obj) {
          this.store = <Store>obj;
          resolve(this.store);
        },
        error(error) {
          console.log(error);
        }
      });
      /*this.localStorage.getItem<any>('store_object').subscribe((store_object) => { console.log(store_object);
          if (store_object) {
              this.store = <Store> store_object;
              resolve(this.store);
          }
      });*/
    });
    return promise;
  }

  getCurrency() {
    return 'KD';
  }

  setCurrency(currency) {
    window.localStorage.setItem('currency', currency);
  }

  getCartId() {
    return window.localStorage.getItem('cart-id');
  }

  setCartId(cart_id) {
    window.localStorage.setItem('cart-id', cart_id);
  }

  toggleLoading(key: boolean) {
    this.loading = key;
    this.load.emit(this.loading);
  }

  getCartCount() {
    if (window.localStorage.getItem('cart-count') === null) {
      this.setCartCount(0);
    }
    return window.localStorage.getItem('cart-count');
  }

  setCartCount(cart_count) {
    window.localStorage.setItem('cart-count', cart_count);
  }

  getWishlistCount() {
    if (window.localStorage.getItem('wishlist-count') === null) {
      this.setWishlistCount(0);
    }
    return window.localStorage.getItem('wishlist-count');
  }

  setWishlistCount(cart_count) {
    window.localStorage.setItem('wishlist-count', cart_count);
  }

  getBrowserVersion() {
    let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return M.join(' ');
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  }

  roundUp(num, precision) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
  }

  getBase64Image(src, callback) {
    const image = new Image();
    image.crossOrigin = '*';
    image.src = src;
    image.onload = () => {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0);
      let dataURL = canvas.toDataURL("image/png");
      dataURL = dataURL.split(',')[1];

      callback(dataURL, image.width, image.height);
    };
  }

  formatDate(value:string) {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals = {
        'year': 31536000,
        //'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      const texts = {
        'year': 'YEAR_AGO',
        //'month': 'MONTH_AGO',
        'week': 'WEEK_AGO',
        'day': 'DAY_AGO',
        'hour': 'HOUR_AGO',
        'minute': 'MINUTE_AGO',
        'second': 'SECOND_AGO'
      };
      const texts_plural = {
        'year': 'YEARS_AGO',
        //'month': 'MONTHS_AGO',
        'week': 'WEEKS_AGO',
        'day': 'DAYS_AGO',
        'hour': 'HOURS_AGO',
        'minute': 'MINUTES_AGO',
        'second': 'SECONDS_AGO'
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return {
              value: counter,
              label: texts[i]
            }
          } else {
            return {
              value: counter,
              label: texts_plural[i]
          }
          }
      }
    }
    return value;
  }
}
