import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {MagazinesComponent} from "./magazines/magazines.component";

const routes: Routes = [
    {
        path: 'product',
        loadChildren: () => import('./product-list/product-list.module').then(mod => mod.ProductListModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./register/register.module').then(mod => mod.RegisterModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule)
    },
    {
        path: 'cart',
        loadChildren: () => import('./cart/cart.module').then(mod => mod.CartModule)
    },
    {
        path: 'shipping-address',
        loadChildren: () => import('./shipping-address/shipping-address.module').then(mod => mod.ShippingAddressModule)
    },
    {
        path: 'payment',
        loadChildren: () => import('./payment/payment.module').then(mod => mod.PaymentModule)
    },
    {
        path: 'order-confirmation',
        loadChildren: () => import('./order-confirmation/order-confirmation.module').then(mod => mod.OrderConfirmationModule)
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
    },
	 {
        path: 'pro-list',
        //loadChildren: () => import('./pro-categories-all/pro-categories-all.module').then(mod => mod.ProCategoriesAllModule)
         loadChildren: () => import('./pro-categories/pro-categories.module').then(mod => mod.ProCategoriesModule)
    },
    {
        path: 'pro-list/:id',
        loadChildren: () => import('./pro-categories/pro-categories.module').then(mod => mod.ProCategoriesModule)
    },
    {
        path: 'professional',
        loadChildren: () => import('./professional/professional.module').then(mod => mod.ProfessionalModule)
    },
    {
        path: 'professional/:id',
        loadChildren: () => import('./professional/professional.module').then(mod => mod.ProfessionalModule)
    },
    {
        path: 'professional-details/:id',
        loadChildren: () => import('./professional-details/professional-details.module').then(mod => mod.ProfessionalDetailsModule)
    },
    {
        path: 'lookbook',
        loadChildren: () => import('./ideabook/ideabook.module').then(mod => mod.IdeabookModule)
    },
    {
        path: 'lookbook/:id',
        loadChildren: () => import('./ideabook-details/ideabook-details.module').then(mod => mod.IdeabookDetailsModule)
    },
    {
        path: 'sketch/:id',
        loadChildren: () => import('./sketch/sketch.module').then(mod => mod.SketchModule)
    },
    {
        path: 'order/detail/:id',
        loadChildren: () => import('./order-detail/order-detail.module').then(mod => mod.OrderDetailModule)
    },
    {
        path: 'address/add',
        loadChildren: () => import('./address/address.module').then(mod => mod.AddressModule)
    },
    {
        path: 'address/edit/:id',
        loadChildren: () => import('./address/address.module').then(mod => mod.AddressModule)
    },
    {
        path: 'contact-us',
        loadChildren: () => import('./contact-us/contact-us.module').then(mod => mod.ContactUsModule)
    },
    {
        path: 'page',
        loadChildren: () => import('./cms/cms.module').then(mod => mod.CmsModule)
    },
    {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then(mod => mod.FaqModule)
    },
    {
        path: 'seller',
        loadChildren: () => import('./seller/seller.module').then(mod => mod.SellerModule)
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./forgot-password/forgot-password.module').then(mod => mod.ForgotPasswordModule)
    },
    {
        path: 'magazine-categories',
        loadChildren: () => import('./magazine-categories/magazine-categories.modules').then(mod => mod.MagazineCategoriesModules)
    },
    /*{
        path: 'magazines',
        loadChildren: () => import('./magazines/magazines.module').then(mod => mod.MagazinesModule)
        //component: MagazinesComponent
    },*/
    /*{
        path: 'magazines/:id',
        component: MagazinesComponent
    },*/
    {
        path: 'shop-categories',
         loadChildren: () => import('./shop-categories/shop-categories.module').then(mod => mod.ShopCategoriesModule)
    },
    {
        path: 'magazine/details/:id',
        loadChildren: () => import('./magazine-details/magazine-details.module').then(mod => mod.MagazineDetailsModule)
    },
    {
        path: 'all-vendors',
        loadChildren: () => import('./all-vendors/all-vendors.module').then(mod => mod.AllVendorsModule)
    },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
