import {AfterViewInit, Component, DoCheck, HostListener, OnInit} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Product} from '../models/product';
import {Attributes} from '../models/attributes';
import {CryptService} from '../config/crypt.service';
import {CartService} from '../config/cart.service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {ConfigSettings} from '../config/config.settings';
import {MatDialog} from '@angular/material/dialog';
import {AddedToCartDialogComponent} from '../added-to-cart-dialog/added-to-cart-dialog.component';
import {Store} from '../models/store';
import {ConfigurableOptions} from '../models/configurable-options';
import {DialogComponent} from '../dialog/dialog.component';
import {UserService} from '../config/user.service';
import {WishlistService} from '../config/wishlist.service';
import {fade} from '../animations';
import {FooterService} from '../config/footer.service';
import {reject} from 'q';
import {DateFormatPipe} from '../config/DateFormatPipe';
import {ProductService} from '../config/product.service';
import {SaveToLookbookComponent} from '../save-to-lookbook/save-to-lookbook.component';
import {trigger, state, style, animate, transition, keyframes} from '@angular/animations';

declare var branch: any;
declare var $: any;

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: [
    './product-detail.component.css'
  ],
  animations: [
    trigger('buttonTextStateTrigger', [
      state('shown', style({
        opacity: 1,
      })),
      state('transitioning', style({
        opacity: 0.3
      })),
      state('transitioned', style({
        opacity: 1
      })),
      transition('shown => transitioning', animate('600ms ease-out')),
      transition('transitioning => shown', animate('600ms ease-in')),
      transition('shown => transitioned', animate('600ms ease-in')),
      transition('transitioned => shown', animate('3000ms ease-in', keyframes([
          style({backgroundColor: '#494f54'}),
          style({backgroundColor: '#636669'}),
          style({backgroundColor: '#838486'}),
          style({backgroundColor: '#b2b4b7'}),
          style({backgroundColor: '#cccccc'})
        ])
      )),
      transition('transitioned => final_transition', animate('200ms ease-in', keyframes([
          style({backgroundColor: '#cccccc'})
        ])
      )),
      transition('final_transition => shown', animate('1500ms ease-in', keyframes([
          style({backgroundColor: '#cccccc'}),
          style({backgroundColor: '#b2b4b7'}),
          style({backgroundColor: '#838486'}),
          style({backgroundColor: '#636669'}),
          style({backgroundColor: '#494f54'}),
          style({backgroundColor: '#343a40'})
        ])
      )),

    ])]
})
export class ProductDetailComponent implements OnInit, AfterViewInit {

  // The current state of the button text
  buttonTextState = 'shown';
  // The text currently being show
  buttonText = 'Add to Cart';
  // The text that will be shown when the transition is finished
  transitionButtonText = 'Add to Cart';

  /**
   * Respond to the transition event of the button text
   * by setting the text awaiting transition then setting the state as shown
   */
  buttonTextTransitioned(event) {
    this.buttonText = this.transitionButtonText;
    this.buttonTextState = this.buttonTextState = 'shown';
  }

  product: Product;
  id: number;
  selectedAttribute: any[] = [];
  selectedOption: Number[] = [];
  selectedFilters: ConfigurableOptions[] = [];
  entityId: string;
  quantity: number;
  quantityArr: any[] = [];
  lang: string;
  store: Store;
  currency: string;
  userId: string;
  dataAvailable = false;
  slideConfig: any = {
    variableWidth: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    prevArrow: '<span class="slick-prev" style=""><span class="slick-prev-icon"></span></span>',
    nextArrow: '<span class="slick-next" style=""><span class="slick-next-icon"></span></span>',
    asNavFor: '.gallery-thumb',
    rtl: (this.lang == 'ar') ? true : false
  };
  thumbSlideConfig: any = {
    variableWidth: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    prevArrow: '<span class="slick-prev" style=""><span class="slick-prev-icon"></span></span>',
    nextArrow: '<span class="slick-next" style=""><span class="slick-next-icon"></span></span>',
    asNavFor: '.gallery-main',
    focusOnSelect: true,
    rtl: (this.lang == 'ar') ? true : false
  };
  branchIoLink: string;
  start_date: string;
  end_date: string;
  enableAddToCart: boolean = false;
  final_price: number;
  regular_price: number;
  off_percentage = 0;
  off_percentage_text: string;
  recentlyViewed: Product[] = [];
  minDate = new Date();
  panelOpenState = false;
  rent_time: string = '';
  min_rent_time: string = '00:00';
  singleAttributeOption: number = 0;
  goToCart: number = 0;
  showLess_More: string = 'SEE MORE...';
  paasValueOn_SeeMoreBtn: boolean = true;

  constructor(
    private configService: ConfigService,
    private configSettings: ConfigSettings,
    private route: ActivatedRoute,
    private cryptService: CryptService,
    private cartService: CartService,
    protected localStorage: LocalStorage,
    private dialog: MatDialog,
    private userService: UserService,
    private wishlistService: WishlistService,
    protected footerService: FooterService,
    private router: Router,
    private dateFormatPipe: DateFormatPipe,
    private productService: ProductService
  ) {
    window.scrollTo(0, 0);
    this.quantity = 1;

    this.route.params.subscribe(val => {
      // put the code from `ngOnInit` here
      // console.log();

      this.configSettings.toggleLoading(true);

      // this.id = +this.route.snapshot.paramMap.get('id');
      this.id = +parseInt(val.id);

      this.lang = this.configSettings.getLang();

      this.showLess_More = (this.lang == 'ar') ? 'شاهد المزيد...' : 'SEE MORE...';
      this.userService.getUserSession().then(user => {
        if (user !== false) {
          this.userId = user.id;
        }

        /* getting store from config*/
        this.configSettings.getStoreObject().then((result) => {
          this.store = <Store>result;
          this.currency = result['currency_' + this.lang];
          this.getProductDetails();
        });
      }, error => {
        this.configSettings.toggleLoading(false);
      });
    });
  }

  ngOnInit() {
    /*this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });*/

    /*if(this.lang === 'ar') {
        this.thumbSlideConfig = {
          variableWidth: false,
          infinite: true,
          speed: 300,
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          asNavFor: '.gallery-main',
          focusOnSelect: true,
          rtl: true
        };

        this.slideConfig = {
          variableWidth: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          prevArrow: '<span class="slick-prev" style=""><span class="slick-prev-icon"></span></span>',
          nextArrow: '<span class="slick-next" style=""><span class="slick-next-icon"></span></span>',
          asNavFor: '.gallery-thumb',
          rtl: false
        };
    }*/
  }

  ngAfterViewInit() {
  }

  getProductDetails() {
    const params = {
      product_id: this.id,
      lang: this.lang,
      user_id: this.userId,
      store: this.store.iso_code
    };
    this.configService.readRequest('product-details', params)
      .subscribe(response => {
        if (response.status === 200) {
          this.product = response.body.data;
          this.addToRecentlyViewed(this.product);
          this.final_price = parseFloat(this.product.final_price);
          this.regular_price = parseFloat(this.product.regular_price);
          if (this.regular_price > this.final_price) {
            this.off_percentage = Math.round(100 - ((this.final_price / this.regular_price) * 100));
            this.off_percentage_text = this.off_percentage + '%';
          }

          this.product.parent_id = this.product.id;
          this.entityId = (this.product.configurable_option.length === 0) ? this.product.id : null;

          if (this.product.buffer_time > 0) {
            this.minDate.setDate(this.minDate.getDate() + Math.round(this.product.buffer_time / 24));
            this.setBuffer();
          }

          if (this.product.is_rental === 1) {
            this.enableAddToCart = false;
          } else {
            this.enableAddToCart = true;
          }

          /*
          * Auto selecting dropdown if there is only one option
          * */
          if (this.product.configurable_option.length > 0) {
            let singleOption = true;
            for (let i = 0; i < this.product.configurable_option.length; i++) {
              if (i == 0 && this.product.configurable_option[i].attributes.length > 1) {
                this.selectedAttribute[i] = this.product.configurable_option[i].attributes[0].option_id;
              }
              if (this.product.configurable_option[i].attributes.length === 1) {
                //this.selectedAttribute[i] = this.product.configurable_option[i].attributes[0].option_id;
              } else {
                singleOption = false;
                break;
              }
            }
            if (singleOption) {
              this.entityId = this.product.id;
            } else {
              //this.selectedAttribute = [];
            }
          }
          /*
          * Adding quantity options in dropdown if quantity is available and product is simple
          * */
          if (this.product.product_type === 'Simple' && this.product.remaining_quantity > 0 && this.product.is_rental !== 1) {
            this.setQuantityDropdown(this.product.remaining_quantity);
            if (this.product.configurable_option.length == 1) {
              if (this.product.configurable_option[0].attributes.length === 1) {
                this.singleAttributeOption = 1;
              }
            }
          } else if (this.product.configurable_option.length == 1) {
            if (this.product.configurable_option[0].attributes.length > 0) {
              if (this.product.is_rental !== 1) {
                this.setQuantityDropdown(this.product.remaining_quantity);
              }
              if (this.product.configurable_option[0].attributes.length === 1) {
                this.singleAttributeOption = 1;
              }
            } else {
              this.quantityArr = [];
            }
          }

          this.configSettings.toggleLoading(false);
          this.dataAvailable = true;
          setTimeout(() => {
            this.footerService.toggleFooter(true);
          }, 300);

          this.registerBranch();
          var options = {no_journeys: true};
          branch.init('key_live_lgQJxgoL1T7qBM01abvZdkfauuhw6i1p', options, function (err, data) {
            //console.log(err, data);
          });

          var linkData = {
            campaign: 'content 123',
            channel: 'facebook',
            feature: 'share',
            stage: 'new user',
            tags: ['tag1', 'tag2', 'tag3'],
            alias: '',
            data: {
              'custom_bool': true,
              'custom_int': Date.now(),
              'custom_string': 'hello',
              '$og_title': 'Shop ' + this.product.name,
              '$og_description': this.product.description,
              '$og_image_url': this.product.image,
              'target': 'P',
              'target_id': this.product.id
            }
          };

          branch.link(linkData, (err, link) => {
            this.branchIoLink = link;
          });
          /*------Slick image slider---------*/
          /*const imageCount = this.product.images.length;
          $(document).ready(function() {
              $('.product-images').slick({
                  variableWidth: false,
                  infinite: true,
                  speed: 300,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: false,
                  asNavFor: '.main-thumb',
                  prevArrow: '<span class="slick-prev" style=""><span class="slick-prev-icon"></span></span>',
                  nextArrow: '<span class="slick-next" style=""><span class="slick-next-icon"></span></span>'
              });

              $('.main-thumb').slick({
                  variableWidth: false,
                  infinite: false,
                  slidesToShow: imageCount,
                  slidesToScroll: 1,
                  asNavFor: '.product-images',
                  arrows: true,
                  dots: false,
                  vertical: true,
                  focusOnSelect: true
              });
          });*/
        } else {
          this.configSettings.toggleLoading(false);
        }
      }, error => {
        this.configSettings.toggleLoading(false);
      });
  }

  addToRecentlyViewed(product: Product) {
    this.productService.getRecentlyViewed().then(products_viewed => {
      let productsViewedArr: Product[] = [];
      if (products_viewed) {
        const index = products_viewed.findIndex(productViewed => productViewed.id === product.id);
        productsViewedArr = products_viewed;
        if (index < 0) {
          productsViewedArr.unshift(product);
        }

        if (productsViewedArr.length > 20) {
          productsViewedArr.splice(-1, 1);
        }
      } else {
        productsViewedArr.unshift(product);
      }
      this.productService.setRecentlyViewed(productsViewedArr);
      productsViewedArr.map(productViewed => {
        if (productViewed.id !== product.id) {
          this.recentlyViewed.push(productViewed);
        }
      });
      this.recentlyViewed.map((item, index) => {
        if (item.id == undefined) {
          this.recentlyViewed.splice(index, 1);
        }
      });
    }, error => {
    });
  }

  onOptionSelect(option, $event) {
    /*setTimeout(() => document.getElementById('option-' + $event.option)
        .querySelector<HTMLInputElement>('.ng-select-container > .ng-value-container > .ng-value > .ng-value-label').innerHTML = $event.selectedText,
        5);*/
    document.getElementById('option-' + option.attribute_id).classList.remove('error');
    this.clearFilters(option, $event).then(() => {
      this.filterOptions();
    });
  }

  clearFilters(configurable_option: ConfigurableOptions, $event): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      this.product.configurable_option.map((option, index) => {
        if (option.attribute_id === configurable_option.attribute_id) {
          if (index == 0) {
            this.selectedAttribute.splice(1);
            this.selectedOption.splice(1);
            this.selectedFilters.splice(1);
          }

          this.selectedAttribute[index] = $event.option_id;
          this.selectedOption[index] = +configurable_option.attribute_id;
          this.selectedFilters[index] = {
            type: configurable_option.type,
            attribute_id: configurable_option.attribute_id,
            attribute_code: configurable_option.attribute_code,
            attributes: [$event]
          };
        }
      });

      resolve(true);
    });

    return promise;

  }

  checkRentalQuantity(q: number) {
    if (this.product.is_rental === 1) {
      if (q > this.product.remaining_quantity) {
        this.enableAddToCart = false;
      } else {
        this.enableAddToCart = true;
      }
    }
  }

  setBuffer() {
    //this.product.buffer_time = 48;
    var dt = new Date(this.minDate);
    dt.setMinutes(dt.getMinutes() + (this.product.buffer_time * 60));
    this.min_rent_time = dt.getHours() + ':' + dt.getMinutes();
    this.rent_time = '';
    this.quantityArr = [];
    this.quantity = 0;
  }

  setRentalData() {
    if (this.product.buffer_time > 0) {
      let min_date = this.minDate.getDate();
      let dt = new Date(this.start_date);
      let selectedDate = dt.getDate();

      if (selectedDate > min_date) {
        this.min_rent_time = '00:00';
        this.checkRentalAvailability();
      } else {
        this.setBuffer();
      }
    }
  }

  checkRentalAvailability() {
    this.end_date = this.start_date;

    if (this.start_date && this.end_date && this.rent_time) {
      if (!this.entityId) {
        let selects = document.querySelectorAll('ng-select');
        for (let i = 0; i < selects.length; i++) {
          selects[i].classList.add('error');
        }
      }

      const postParams = {
        product_id: this.entityId,
        start_date: this.start_date,
        end_date: this.end_date,
        rent_time: this.rent_time
      };

      this.configService.postRequest('check-product-available-qty', null, postParams)
        .subscribe(response => {
          console.log(response);
          if (response.status === 200) {
            this.product.remaining_quantity = response.data.available_qty;
            this.setQuantityDropdown(this.product.remaining_quantity);
            this.enableAddToCart = true;
            //resolve(true);
          } else {
            reject(response);
          }
        }, error => {
          reject(error);
        });
    }
  }

  addtoCartAnimation() {
    setTimeout(() => {
      this.buttonTextState = 'transitioning';
      this.transitionButtonText = 'OK';
    }, 1800);

    setTimeout(() => {
      this.buttonTextState = 'transitioned';
      this.transitionButtonText = (this.lang == 'ar') ? 'Go to Cart' : 'Go to Cart';
      this.goToCart = 1;
    }, 4000);

    // Reset button text
    setTimeout(() => {
      this.buttonTextState = 'final_transition';
      this.transitionButtonText = (this.lang == 'ar') ? 'Add to Cart' : 'Add to Cart';
      this.goToCart = 0;
    }, 7000);
  }

  addToBag() {
    try {

      if (this.goToCart === 2) {
        // Add to cart in progress, do nothing
        return;
      }

      if (this.goToCart === 1) {
        window.location.href = '/cart';
        return;
      }

      if (this.product.is_saleable && this.enableAddToCart) {

        this.goToCart = 2;
        this.buttonTextState = 'transitioning';
        this.transitionButtonText = 'spinner';

        if (this.entityId) {
          this.localStorage.getItem<any>('userInfo').subscribe((userInfo: string) => {
            if (userInfo) {
              this.addToOnlineCart(userInfo).then((any) => {
                this.configSettings.toggleLoading(false);
                // const dialogRef = this.dialog.open(AddedToCartDialogComponent);
                this.addtoCartAnimation();
              }, error => {
                console.log('Error occured while adding product to cart!');
                this.goToCart = 0;
              });
            } else {
              this.cartService.addToOfflineCart(this.entityId, this.product, this.selectedFilters, this.quantity, this.start_date, this.end_date, this.rent_time).then(response => {
                this.configSettings.toggleLoading(false);
                this.addtoCartAnimation();
                // const dialogRef = this.dialog.open(AddedToCartDialogComponent);
              });
            }
          }, error => {
            this.configSettings.toggleLoading(false);
            this.goToCart = 0;
          });
        } else {
          this.configSettings.toggleLoading(false);
          this.goToCart = 0;
          let selects = document.querySelectorAll('ng-select');
          for (let i = 0; i < selects.length; i++) {
            selects[i].classList.add('error');
          }
        }
      }
    } catch (e) {
      this.configSettings.toggleLoading(false);
      console.log(e);
      this.goToCart = 0;
    }
  }

  private addToOnlineCart(user: string): Promise<any> {
    return new Promise(resolve => {
      this.cryptService.doDecrypt(user).then(decryptedString => {
        const user = JSON.parse(decryptedString);

        const getParams = {
          lang: this.lang,
          store: this.store
        };
        const postParams = {
          user_id: user.id,
          products: this.entityId,
          quantity: this.quantity,
          is_rental: this.product.is_rental,
          start_date: (this.start_date) ? this.dateFormatPipe.transform(new Date(this.start_date), 'yyyy-MM-dd') : '',
          end_date: (this.end_date) ? this.dateFormatPipe.transform(new Date(this.end_date), 'yyyy-MM-dd') : '',
          rent_time: this.rent_time
        };
        this.configService.postRequest('add-to-cart', getParams, postParams)
          .subscribe(response => {
            if (response.status === 200) {
              this.configSettings.setCartId(response.data.id);
              let cartTotalQty = 0;
              response.data.items.map((item, index) => {
                cartTotalQty += item.quantity;
              });
              this.cartService.changCartCount(cartTotalQty);
              resolve(true);
            } else {
              this.configSettings.toggleLoading(false);
              const dialogRef = this.dialog.open(AddedToCartDialogComponent, {
                data: {
                  title: response.message
                }
              });
              reject(response);
            }
          }, error => {
            this.goToCart = 0;
            reject(error);
          });
      });
    });
  }

  toggleWishList($event) {
    if (this.userId) {
      this.configSettings.toggleLoading(true);
      const getParams = {
        lang: this.lang,
        store: this.store.iso_code
      };

      const postParams = {
        product_id: this.id,
        user_id: this.userId
      };

      if (this.product.item_in_wishlist) {
        this.configService.postRequest('remove-wishlist', getParams, postParams)
          .subscribe(response => {
            if (response.status.toString() === '200') {
              this.product.item_in_wishlist = 0;
              this.configSettings.toggleLoading(false);
              this.wishlistService.changWishlistCount(response.data.length);
            } else {
              this.configSettings.toggleLoading(false);
            }
          }, error => {
            this.configSettings.toggleLoading(false);
          });
      } else {
        this.configService.postRequest('add-wishlist', getParams, postParams)
          .subscribe(response => {
            if (response.status.toString() === '200') {
              this.product.item_in_wishlist = 1;
              this.configSettings.toggleLoading(false);
              this.wishlistService.changWishlistCount(response.data.length);
            } else {
              this.configSettings.toggleLoading(false);
            }
          }, error => {
            this.configSettings.toggleLoading(false);
          });
      }
    } else {
      window.location.href = '/login?returnUrl=product/detail/' + this.id;
    }
  }

  filterOptions() {
    this.configSettings.toggleLoading(true);

    const getParams = {
      lang: this.lang,
      store: this.store.iso_code
    };

    const postParams = {
      product_id: this.product.id,
      option_id: (this.selectedAttribute.length) ? this.selectedAttribute.join(',') : '',
      attribute_id: (this.selectedOption.length) ? this.selectedOption.join(',') : ''
    };

    this.configService.postRequest('configurable-options', getParams, postParams)
      .subscribe(response => {
        console.log(response);
        if (response.data[0].attributes.length) {
          this.product.configurable_option.map(option => {
            if (option.attribute_id === response.data[0].attribute_id) {
              option.attributes = [];
              option.attributes = response.data[0].attributes;
              option.attributes.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
            }
          });

          this.product.images = response.data[0].images;
          this.quantityArr = [];
        } else {
          // this.product.currency_code = response.data[0].currency_code;
          // this.product.final_price = response.data[0].final_price;
          this.product.image = response.data[0].image_url;
          this.product.images = response.data[0].images;
          // this.product.regular_price = response.data[0].regular_price;
          this.product.SKU = response.data[0].sku;
          this.product.remaining_quantity = response.data[0].remaining_quantity;
          this.entityId = response.data[0].entity_id;

          if (this.product.remaining_quantity > 0) {
            this.setQuantityDropdown(this.product.remaining_quantity);
          } else {
            this.quantityArr = [];
          }
        }

        this.configSettings.toggleLoading(false);
      }, error => {
        this.configSettings.toggleLoading(false);
      });
  }

  showSizeGuide() {
    let dialogRef = this.dialog.open(DialogComponent, {
      data: {value: this.product.size_guide, image: true},
    });
    return false;
  }

  showGallery(index) {
    let dialogRef = this.dialog.open(DialogComponent, {
      data: {images: this.product.images, gallery: true},
      height: '95%',
      maxWidth: 920
    });
  }

  setQuantityDropdown(remaining_quantity: number) {
    this.quantityArr = [];
    //const maxDropdownQuantity = (this.product.remaining_quantity > 10) ? 10 : this.product.remaining_quantity;
    const maxDropdownQuantity = (this.product.remaining_quantity > 0) ? this.product.remaining_quantity : 0;

    const minimumQuantity = (this.product.minimum_quantity > 0) ? this.product.minimum_quantity : 1;

    for (let i = minimumQuantity; i <= maxDropdownQuantity; i++) {
      this.quantityArr.push({value: i, label: i});
    }
    if(minimumQuantity > 1 ){
        this.quantity = minimumQuantity;
    }else{
        this.quantity = 1;
    }
  }

  registerBranch() {
    this.registerBranchSource(window, document, 'script', 'branch', function (b, r) {
      b[r] = function () {
        b._q.push([r, arguments]);
      };
    }, {
      _q: [],
      _v: 1
    }, 'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking'.split(' '), 0);
  }

  registerBranchSource(b, r, a, n, c, h, _, s) {
    if (!b[n] || !b[n]._q) {
      for (; s < _.length;) {
        c(h, _[s++]);
      }
      let d = r.createElement(a);
      d.async = 1;
      d.src = 'https://cdn.branch.io/branch-latest.min.js';
      let k = r.getElementsByTagName(a)[0];
      k.parentNode.insertBefore(d, k);
      b[n] = h;
    }
  }

  saveToLookBook() {
    if (!this.userId) {
      window.location.href = '/login?returnUrl=product/detail/' + this.id;
      return;
    }
    const dialogRef = this.dialog.open(SaveToLookbookComponent, {
      width: '90%',
      maxWidth: 650,
      data: {
        type: 'P',
        product: this.product
      }
    });
  }

  showMore(data: boolean) {
    if (data) {
      $('#slicedDesc').css('display', 'none');
      $('#fullDesc').css('display', 'inline');
      this.showLess_More = (this.lang == 'ar') ? 'انظر أقل...' : 'SEE LESS...';
      this.paasValueOn_SeeMoreBtn = false;
    } else {
      $('#slicedDesc').css('display', 'inline');
      $('#fullDesc').css('display', 'none');
      this.showLess_More = (this.lang == 'ar') ? 'شاهد المزيد...' : 'SEE MORE...';
      this.paasValueOn_SeeMoreBtn = true;

      document.getElementById('productDescription').scrollIntoView({behavior: 'smooth', block: 'start', inline: "nearest"});
    }
  }
}
