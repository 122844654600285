import {NgModule} from "@angular/core";
import {ProductDetailComponent} from "./product-detail.component";
import {SlickModule} from "ngx-slick";
import {SharedModule} from "../shared/shared.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTabsModule} from "@angular/material/tabs";
import {ProductItemModule} from "../product-item/product-item.module";
import {DragScrollModule} from "ngx-drag-scroll";
import {AddedToCartDialogModule} from "../added-to-cart-dialog/added-to-cart-dialog.module";
import {MatNativeDateModule} from "@angular/material/core";
import {SaveToLookbookModule} from "../save-to-lookbook/save-to-lookbook.module";
import { ShareButtonsModule } from '@ngx-share/buttons';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

@NgModule({
    imports: [
        SharedModule,
        SlickModule.forRoot(),
        NgSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTabsModule,
        ProductItemModule,
        DragScrollModule,
        AddedToCartDialogModule,
        SaveToLookbookModule,
        ShareButtonsModule,
	    MatExpansionModule,
        NgxMaterialTimepickerModule
    ],
    declarations: [
        ProductDetailComponent
    ],
    exports: [],
    providers: [
        SlickModule,
        NgSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTabsModule,
        ProductItemModule,
        DragScrollModule
    ]
})

export class ProductDetailModule {

}
