import { NgModule, Injectable } from "@angular/core";
import {ProductItemComponent} from "./product-item.component";
import {NgxTextOverflowClampModule} from "ngx-text-overflow-clamp/dist";
import {SharedModule} from "../shared/shared.module";
import {intersectionObserverPreset, LazyLoadImageModule} from "ng-lazyload-image";

@Injectable()
@NgModule({
    imports: [
        SharedModule,
        NgxTextOverflowClampModule,
    ],
    declarations: [
        ProductItemComponent
    ],
    exports: [
        ProductItemComponent,
        NgxTextOverflowClampModule
    ],
    providers: []
})

export class ProductItemModule {}
