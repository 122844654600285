import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Professional} from "../models/professional";
import {Ideabook} from "../models/ideabook";
import {User} from "../models/user";
import {UserService} from "../config/user.service";
import {ConfigService} from "../config/config.service";
import {ConfigSettings} from "../config/config.settings";
import {reject} from "q";

@Component({
  selector: 'app-save-to-lookbook',
  templateUrl: './save-to-lookbook.component.html',
  styleUrls: ['./save-to-lookbook.component.css']
})
export class SaveToLookbookComponent implements OnInit {
  lang: string;
  dataAvailable = false;
  ideabooks: Ideabook[] = [];
  user: User = new User();
  selectedIdea: Ideabook;
  tag: string = "";
  comment: string = "";

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private userService: UserService,
      private configService: ConfigService,
      private configSettings: ConfigSettings
  ) { }

  ngOnInit() {
    this.lang = this.configSettings.getLang();
    this.userService.getUserSession().then(response => {
      if (response !== false) {
        this.user = response;
      }

      this.getIdeaBooks()
    });
  }

  saveToLookBook() {
    let ref = this;
    //let imageUrl = (this.data.type === 'F') ? this.data.professional.logo : this.data.product.image;

    const getParams = {
      lang: ref.lang
    };
    const postParams = {
      ideabook_id: ref.selectedIdea.id,
      type: ref.data.type,
      type_id: (ref.data.type === 'F') ? ref.data.professional.id.toString() : ref.data.product.id.toString(),
      product_id: "",
      point_x: "",
      point_y: "",
      image_width: "",
      image_height: "",
      image: "",
      comments: this.comment,
      user_id: this.user.id
    };
    ref.configService.postRequest('create-idea', getParams, postParams)
        .subscribe(response => {
          ref.configSettings.toggleLoading(false);

          if(response.status == 200) {
            //show success
          }
        }, error => { reject(error); });
  }

  getIdeaBooks() {
    const params = {
      lang: this.lang,
      user_id: this.user.id
    };

    this.configService.readRequest('idea-book-list', params)
        .subscribe(response => {
          this.ideabooks = [];
          if (response.status == 200 && response.body.data.length) {
            response.body.data.map(idea => this.ideabooks.push(idea));
          }

          if(this.ideabooks.length) {
            this.selectedIdea = this.ideabooks[0];
          }

          this.dataAvailable = true;
        }, error => { });
  }

  createIdeaBook() {
    this.configSettings.toggleLoading(true);

    try {
      const getParams = {
        lang: this.lang
      };
      const postParams = {
        user_id: this.user.id,
        title: this.tag,
        description: ""
      };
      this.configService.postRequest('create-idea-book', getParams, postParams)
          .subscribe(response => {
            if(response.status === 200) {
              this.tag = "";
              this.getIdeaBooks();
            }
            this.configSettings.toggleLoading(false);
          }, error => { reject(error); });

    } catch (e) {
      console.log(e);
      this.configSettings.toggleLoading(false);
    }
  }

}
