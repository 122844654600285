import {NgModule} from "@angular/core";
import {HomeRoutingModule} from "./home-routing.module";
import {HomeComponent} from "./home.component";
import {SharedModule} from "../shared/shared.module";
import {DragScrollModule} from "ngx-drag-scroll";
import {ProductItemModule} from "../product-item/product-item.module";
import {intersectionObserverPreset, LazyLoadImageModule} from "ng-lazyload-image";
import {ProductDetailModule} from "../product-detail/product-detail.module";
import { SlickModule } from 'ngx-slick';


@NgModule({
    imports: [
        SharedModule,
        HomeRoutingModule,
        DragScrollModule,
        ProductItemModule,
        ProductDetailModule,
        SlickModule.forRoot()

    ],
    declarations: [
        HomeComponent
    ],
    exports: [
        DragScrollModule,
        ProductItemModule,
        ProductDetailModule
    ],
    providers: []
})

export class HomeModule {}
