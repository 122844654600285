import { Component, OnInit } from '@angular/core';
import {ConfigSettings} from "../config/config.settings";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  loading: boolean = false;

  constructor(
      private configSettings: ConfigSettings
  ) { }

  ngOnInit() {
      this.configSettings.load.subscribe(loading => {this.loading = loading;});
  }

}
