import {NgModule} from "@angular/core";
import {SharedModule} from "../shared/shared.module";
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from "@angular/material/dialog";
import {DialogComponent} from "./dialog.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {MatSnackBarModule} from "@angular/material/snack-bar";

@NgModule({
    imports:[
        SharedModule,
        MatDialogModule,
        MatSnackBarModule,
        NgSelectModule
    ],
    declarations: [DialogComponent],
    exports: [DialogComponent],
    providers: [{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}}],
    entryComponents: [DialogComponent]
})

export class DialogModule {

}
