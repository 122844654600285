import {AfterViewInit, Component, HostListener, Input, OnInit} from '@angular/core';
import {NavbarService} from '../config/navbar.service';
import {Category} from '../models/category';
import {ConfigSettings} from '../config/config.settings';
import {ConfigService} from '../config/config.service';
import {Faq} from '../models/faq';
// import {forEach} from '@angular/router/src/utils/collection';
import {Store} from '../models/store';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {NotificationService} from '../config/notification.service';
import {Notification} from '../models/notification';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Brands} from '../models/brands';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, finalize, map, switchMap, tap, throttleTime} from 'rxjs/operators';
import {CartService} from '../config/cart.service';
import {Suggestion} from '../models/suggestion';
import {Subject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PopularSearchService} from '../config/popular-search.service';
import {UserService} from '../config/user.service';
import {User} from '../models/user';
//import {AuthService} from 'angularx-social-login';
import {WishlistService} from '../config/wishlist.service';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  overlayHeight = '0px';
  sideMenuWidth = '0px';
  isSideMenuOpen = false;
  isStoreSwitcherOpen = false;
  navigationStatus = true;
  website_header_text: string;
  categories: Category[] = [];
  rental_categories: Category[] = [];
  pro_categories: Category[] = [];
  subCategories: Category[] = [];
  @Input() stores: Store[] = [];
  @Input() store: Store;
  hiddenOverlay = true;
  notifications: Notification[] = [];
  lang: string;
  q: string;
  isLoading = false;
  searchForm: FormGroup;
  suggestions: Suggestion[] = [];
  recentSearches: Suggestion[] = [];
  popularSearches: Suggestion[] = [];
  resize = new Subject();
  top_selected_category: string;
  top_selected_category_name: string;
  user: User;
  userStatus = false;
  userSession = new User();
  selectedBottomMenu: string;

  constructor(
    private navbarService: NavbarService,
    private configService: ConfigService,
    public configSettings: ConfigSettings,
    private notificationService: NotificationService,
    private popularSearchService: PopularSearchService,
    protected localStorage: LocalStorage,
    private translate: TranslateService,
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private http: HttpClient,
    private userService: UserService,
    //private authService: AuthService,
    private wishlistService: WishlistService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.navbarService.getWebsiteHeaderText.subscribe(website_header_text => {
      this.website_header_text = website_header_text;
    });
    this.navbarService.change.subscribe(status => {
      this.navigationStatus = status;
      console.log('Header: ' + this.navigationStatus);
    });
    this.getRecentSearch();
    this.readAllCategories();
    this.readNotifications();
    //this.readRentalCategories();
    //this.readProCategories();
    this.readPopularSearch();

    this.lang = this.configSettings.getLang();

    this.searchForm = this.formBuilder.group({
      q: null
    });

    this.searchForm.get('q').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.cartService.search({q: value, search_type: this.top_selected_category})
        .pipe(
         finalize(() => this.isLoading = false),
        )
      )
    ).subscribe(results => {
      if (results.body.status === 200 && results.body.data.length) {
        this.suggestions = results.body.data;
      } else {
        this.suggestions = [];
      }
    });

    if (window.localStorage.getItem('top-category') !== null) {
      this.top_selected_category = window.localStorage.getItem('top-category');
      this.navbarService.setTopCategory(this.top_selected_category);
    }

    this.userService.getUserSession().then(response => {
      if (response !== false) {
        this.user = response;
        this.userSession.id = response.id;
        this.userSession.first_name = response.first_name;
        this.userSession.last_name = response.last_name;
        this.userSession.email = response.email;
      }
      this.userStatus = true;
    });
    console.log('top_selected_category: ' + this.top_selected_category);
  }

  displayFn(query: Suggestion) {
    if (query) {
      return query.name;
    }
  }

  optionSelected($event) {
    if ($event) {
      this.doBlur();
      this.closeSearch();
      /**
       * Add selected option to local recent_search
       */
      this.localStorage.getItem<any>('recent_search').subscribe((recent_search: any) => {
        let recentSearchArr = [];
        if (recent_search) {
          const index = recent_search.findIndex(recent_search => recent_search.id === $event.id);
          //console.log('Search index: ' + index); return false;
          recentSearchArr.push(recent_search);
          if (index < 0) {
            recentSearchArr.push($event);
          }
        } else {
          recentSearchArr = [$event];
        }
        this.localStorage.setItem('recent_search', recentSearchArr).subscribe((result) => {
          this.recentSearches = <Suggestion[]>recentSearchArr;
          if ($event.type === 'C') {
            // this.router.navigate(['/category/', this.configSettings.cleanUrl($event.name), $event.id]);
            window.location.href = '/product/category/' + this.configSettings.cleanUrl($event.name) + '/' + $event.id;
          } else if ($event.type === 'P') {
            // this.router.navigate(['/product/detail/', $event.id]);
            window.location.href = '/product/detail/' + $event.id;
          } else if ($event.type === 'B' || $event.type === 'BR') {
            // this.router.navigate(['/brand/', this.configSettings.cleanUrl($event.name), $event.id]);
            window.location.href = '/brand/' + this.configSettings.cleanUrl($event.name) + '/' + $event.id;
          }
        });

      });
    }
  }

  getRecentSearch() {
    this.localStorage.getItem<any>('recent_search').subscribe((recent_search) => {

      if (recent_search) {
        this.recentSearches = <Suggestion[]>recent_search;
      }
    });
  }

  changeLanguage(event: any) {
    const newLang = (this.lang === 'ar') ? 'en' : 'ar';
    this.configSettings.setLang(newLang);
    /* Removing notification stored in local storage */
    this.localStorage.removeItem('notifications').subscribe(() => {
      window.location.href = '/';
    });
  }

  doFocus() {
    document.querySelector('nav.header-nav').classList.add('search-active');
  }

  doBlur() {
    document.querySelector('nav.header-nav').classList.remove('search-active');
  }

  hideSearch(event: any) {
    document.querySelector('nav.header-nav').classList.remove('search-active');
    /* Do not hide  if user has clicked inside the dropdown */
    /*var element = document.getElementById('mat-autocomplete-1');
    if (element) {
        if(!element.contains(event.target)) {
            document.querySelector('nav.header-nav').classList.remove('search-active');
        }
    }*/
  }

  clearRecentSearchHistory() {
    this.isLoading = true;
    this.localStorage.removeItem('recent_search').subscribe(() => {
      this.recentSearches = [];
      this.isLoading = false;
    }, error => {
    
    });
  }

  openSearch() {
    this.overlayHeight = 'auto';
    return false;
  }

  closeSearch() {
    this.overlayHeight = '0px';
    return false;
  }

  openNav() {
    if (!this.isSideMenuOpen) {
      this.isSideMenuOpen = true;
      this.sideMenuWidth = '280px';
      document.querySelector('body').classList.add('menu-open');
	  document.querySelector('#side-menu-trigger').classList.add('menu-gray');
    } else {
      this.isSideMenuOpen = false;
      this.sideMenuWidth = '0px';
      document.querySelector('body').classList.remove('menu-open');
	  document.querySelector('#side-menu-trigger').classList.remove('menu-gray');
    document.querySelector('#custom-overlay').classList.remove('menu-gray');
    }
    return false;
  }

  closeSideNav(event) {
    if (this.isSideMenuOpen) {
      this.isSideMenuOpen = false;
      this.sideMenuWidth = '0px';
      document.querySelector('body').classList.remove('menu-open');
    }
  }

  openStoreSwitcher() {
    if (!this.isStoreSwitcherOpen) {
      this.isStoreSwitcherOpen = true;
      document.querySelector('body').classList.add('store-switcher-open');
      /**
       * Removing sidemenu
       */
      this.isSideMenuOpen = false;
      this.sideMenuWidth = '0px';
      document.querySelector('body').classList.remove('menu-open');
    } else {
      this.isStoreSwitcherOpen = false;
      document.querySelector('body').classList.remove('store-switcher-open');
    }
    return false;
  }

  toggleMenu(target: any) {
    $('#menus ul.main-nav li.shop-for-selected').addClass('d-none');
    $('.' + target).removeClass('d-none');
    $('.shop-for .active').removeClass('active');
    $('#' + target).addClass('active');
    /*
    * passing data to top category observable
    * */
    this.setTopCategory(target, '/home');
    // console.log(this.top_selected_category);
    /*this.categories.map(category => {
        if (+category.id === +this.top_selected_category) {
            this.top_selected_category_name = category.name;
        }
    });*/
  }

  setTopCategory(target, url) {
    this.navbarService.setTopCategory(target);
    this.top_selected_category = target;
    window.location.href = url;
  }

  toggleFixMenu(target: any) {
    this.selectedBottomMenu = target;
    if(target == 'shop' || target == 'rental'){
	$('.' + target).removeClass('d-none');
	$('.shop-for .active').removeClass('active');
	$('#top-wishlist-' + target).addClass('active');
	$('#mobile-footer-tabs .link a').removeClass('active');
    	$('#fixmenu-' + target + ' a').addClass('active');
    }
    
    /*
    * passing data to top category observable
    * */
    if(target == 'shop' || target == 'rental'){
	this.navbarService.setTopCategory(target);
	this.top_selected_category = target;
    }
  }

  readAllCategories() {
    const params = {
      lang: this.lang
    };
    this.configService.readRequest('categories-by-type', params)
      .subscribe(response => {
        if (response.body.data) {
          response.body.data.map(obj => {
            if (obj.type === 'P') {
              this.categories = <Category[]>obj.list;

              this.categories.map(category => {
                if (+category.id === +this.top_selected_category) {
                  this.top_selected_category_name = category.name;
                }

                if (category.id.toString() === '1') {
                  this.subCategories = <Category[]>category.subcategories;
                }
              });
            } else if (obj.type === 'R') {
              this.rental_categories = <Category[]>obj.list;
            } else if (obj.type === 'S') {
              this.pro_categories = <Category[]>obj.list;
            }
          });
        }
      });
  }

  readRentalCategories() {
    const params = {
      lang: this.lang,
      type: 'R'
    };
    this.configService.readRequest('all-categories', params)
      .subscribe(response => {
        if (response.body.data) {
          this.rental_categories = <Category[]>response.body.data;
        }
      });
  }

  readProCategories() {
    const params = {
      lang: this.lang,
      type: 'S'
    };
    this.configService.readRequest('all-categories', params)
      .subscribe(response => {
        if (response.body.data) {
          this.pro_categories = <Category[]>response.body.data;
        }
      });
  }

  readPopularSearch() {
    // this.popularSearchService.popularSearch.subscribe(data => this.popularSearches = data);
    this.localStorage.getItem<any>('popular_searches').subscribe((popular_searches) => {
      if (popular_searches) {
        this.popularSearches = <Suggestion[]>popular_searches;
      } else {
        this.popularSearchService.popularSearch.subscribe(data => this.popularSearches = data);
      }
    });
  }

  toggleOverlay(event: any) {
    var store = document.getElementById('store-change');
    var storeDropDown = document.getElementById('store-change-dropdown');
    var category = document.getElementById('category-select');
    var categoryDropDown = document.getElementById('category-select-dropdown');

    if (!store.contains(event.target) && !category.contains(event.target)) {
      this.hiddenOverlay = true;
    } else if ((store.contains(event.target) || category.contains(event.target)) && this.hiddenOverlay) {
      this.hiddenOverlay = false;
    } else if ((store.contains(event.target) || category.contains(event.target)) && !this.hiddenOverlay) {
      if (store.contains(event.target) && storeDropDown.classList.contains('show')) {
        this.hiddenOverlay = true;
      } else if (store.contains(event.target) && !storeDropDown.classList.contains('show')) {
        this.hiddenOverlay = false;
      } else if (category.contains(event.target) && categoryDropDown.classList.contains('show')) {
        this.hiddenOverlay = true;
      } else if (category.contains(event.target) && !categoryDropDown.classList.contains('show')) {
        this.hiddenOverlay = false;
      }
    } else {
      this.hiddenOverlay = true;
    }
    // this.hiddenOverlay = !this.hiddenOverlay;
  }

  hideOverlay(event: any) {
    /* Do not hide overlay if user has clicked inside the dropdown*/
    var store = document.getElementById('store-change-dropdown');
    var category = document.getElementById('category-select');
    if (!store.contains(event.target) && !category.contains(event.target)) {
      this.hiddenOverlay = true;
    }
  }

  changeStore(event: any, id: number) {
    const index = this.stores.findIndex(store => store.id === id);
    var selectedStore = this.stores[index];
    this.setLocalStore(selectedStore);
    window.location.reload();
  }

  onSubmit() {
    console.log(this.q);
    if (this.q) {
      window.location.href = '/search?q=' + this.q;
    }
  }

  openSideMenu(id) {
    if (document.getElementById(id).classList.contains('expand')) {
      document.getElementById(id).classList.remove('expand');

      let children = document.getElementById(id).querySelectorAll('.expand');
      for (let i = 0; i < children.length; i++) {
        children[i].classList.remove('expand');
      }
    } else {
      let siblings = document.getElementById(id).parentElement.parentElement.querySelectorAll('.expand');
      for (let i = 0; i < siblings.length; i++) {
        siblings[i].classList.remove('expand');
      }
      document.getElementById(id).classList.add('expand');

      let siblingUl = document.getElementById(id).querySelectorAll('ul');
      if (!siblingUl.length) {
        this.openNav();
        return true;
      }
    }

    return false;
  }

  SideMenu(id) {
    this.openNav();
    if (document.getElementById(id).classList.contains('expand')) {
      document.getElementById(id).classList.remove('expand');

      let children = document.getElementById(id).querySelectorAll('.expand');
      for (let i = 0; i < children.length; i++) {
        children[i].classList.remove('expand');
      }
      
    } else {
      let siblings = document.getElementById(id).parentElement.parentElement.querySelectorAll('.expand');
      for (let i = 0; i < siblings.length; i++) {
        siblings[i].classList.remove('expand');
      }
      document.getElementById(id).classList.add('expand');
      
      let siblingUl = document.getElementById(id).querySelectorAll('ul');
      if (!siblingUl.length) {
        this.openNav();
        return true;
      }
    }

    return false;
  }

  openSubNav(id, $event) {
     let nav = document.querySelectorAll('ul.main-nav'+'#'+id);	
	if(nav[0].classList.value != 'main-nav top-active'){
			for (let i = 0; i < nav.length; i++) {
			  nav[i].classList.remove('top-active');
			}
	}
	if(nav[0].classList.value =='main-nav top-active'){
    console.log('in');
		document.getElementById(id).classList.remove('top-active')
	}else{
		document.getElementById(id).classList.add('top-active');
	}

    //document.querySelector('.side-menu-top-category').querySelector('.active').classList.remove('active');
    console.log($event.target.classList.value);
    if($event.target.classList.value == 'expand-toggle active'){
    	$event.target.classList.remove('active');
    }else{
    	$event.target.classList.add('active');
    }
    
    return false;
  }

  @HostListener('window:resize', ['$event.target'])
  onresize(target) {
    this.resize.next(target.outerWidth);
  }

  setLocalStore(selectedStore: any) {
    this.localStorage.setItem('store_object', selectedStore).subscribe(() => {
    });
  }

  logout() {
    /*if (this.user.is_social_register) {
      this.authService.signOut();
    }*/
    this.wishlistService.changWishlistCount(0);
    this.cartService.changCartCount(0);
    this.localStorage.removeItem('userInfo').subscribe(() => {
      window.location.href = '/';
    });
  }

  login() {
    window.location.href = '/login';
  }

  showLogoutModal() {
    let dialogRef = this.dialog.open(DialogComponent, {
      data: {logout: true, user: this.user},
    });
  }

  readNotifications() {
    this.notificationService.currentNotification.subscribe(data => this.notifications = data);
    this.localStorage.getItem<any>('notifications').subscribe((notifications) => {
      if (notifications) {
        this.notifications = <Notification[]> notifications;
      } /*else {
                this.notificationService.currentNotification.subscribe(data => this.notifications = data);
            }*/
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    const element = document.getElementById('fixed-header');
    // console.log('window.pageYOffset', window.pageYOffset);
    if (window.pageYOffset > 200) {
      element.classList.add('sticky');
    } else {
      element.classList.remove('sticky');
    }
  }

}
