import {AfterViewInit, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ConfigSettings} from './config/config.settings';
import {FooterService} from './config/footer.service';
import {Store} from './models/store';
import {ConfigService} from './config/config.service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {NavigationEnd, NavigationStart, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{
  lang: string;
  footerStatus: boolean;
  stores: Store[] = [];
  store: Store;

  public static scrollStoreY = [];

  public constructor(
    private translate: TranslateService,
    private configSettings: ConfigSettings,
    private configService: ConfigService,
    private footerService: FooterService,
    private localStorage: LocalStorage,
    private router: Router
  ) {
    this.readAllStores();
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    const lang = this.configSettings.getLang();
    if (lang) {
      translate.use(lang);
    } else {
      translate.use('en');
    }
    this.lang = translate.currentLang;
    this.configSettings.setLang(translate.currentLang);

    this.footerService.footerStatus.subscribe(data => this.footerStatus = data);
  }

  readAllStores() {
    this.configService.readRequest('stores', {})
      .subscribe(response => {
        if (response.body.data) {
          this.stores = <Store[]>response.body.data;
          this.localStorage.setItem('all_stores', this.stores).subscribe(() => {
          });

          this.localStorage.getItem<any>('store_object').subscribe((store_object) => {
            /*
            * If Local Storage is set
            * then set store object
            * else
            * Set kuwait as default store
            * */
            if (store_object) {
              this.store = <Store>store_object;
            } else {
              // this.findCountry().then(response => {
              const isoCode = 'KW';
              // if(response.countryCode) {
              //    isoCode = response.countryCode;
              // }

              const index = this.stores.findIndex(store => store.iso_code === 'KW');
              const selectedStore = this.stores[index];
		if(selectedStore.iso_code === 'KW') {
			selectedStore.currency_en = 'KD';
		}
              this.setLocalStore(selectedStore);
              this.store = <Store>selectedStore;

              /*this.getCountryDetails(isoCode).then(store => {
                if (store.status === 200) {
                  var selectedStore = store.data;
                  this.setLocalStore(selectedStore);
                  this.store = <Store> selectedStore;
                } else {
                  let index = this.stores.findIndex(store => store.iso_code === 'KW');
                  let selectedStore = this.stores[index];
                  this.setLocalStore(selectedStore);
                  this.store = <Store> selectedStore;
                }
              });*/
              //});
            }
            /* Removing selected store from stores array*/
            // const index = this.stores.findIndex(store => store.id === this.store.id);
            // this.stores.splice(index, 1);
          });
        }
      });
  }

  findCountry(): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      this.configService.readRequest('ip-info', {})
        .subscribe(response => {
          if (response.body.data) {
            resolve(response.body.data);
          }
        });
    });

    return promise;
  }

  getCountryDetails(iso_code): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      const params = {
        iso_code: iso_code
      };
      this.configService.readRequest('country-details', params)
        .subscribe(response => {
          if (response.body.data) {
            resolve(response.body);
          }
        });
    });

    return promise;
  }

  setLocalStore(selectedStore: any) {
    this.localStorage.setItem('store_object', selectedStore).subscribe(() => {
    });
  }

  ngOnInit(): void {

    var holdScrollRead = true;

    this.router.events.subscribe((val) => {
      // see also

        //Route Forward
       if(val instanceof NavigationEnd) {

         //console.log('Route Change'+ val.url);
         let scrollTo = AppComponent.scrollStoreY[self.router.url];
         console.log(val.url + ' : ' + scrollTo);

         if(scrollTo != undefined) {
           console.log('Scroll to : ' + scrollTo);
           setTimeout(()=> {
             window.scrollTo(0, scrollTo);
           }, 2000);
         }
         else {
           window.scrollTo(0, 0)
         }

         holdScrollRead = false;
       }

    });

    var self = this;
    window.onscroll = function (event)
    {
      if(holdScrollRead != true) {
        AppComponent.scrollStoreY[self.router.url] = window.pageYOffset;
        //console.log(window.pageYOffset);
      }
    }
  }


}
