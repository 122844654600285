import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    return super.transform(value, format, timezone, locale);
  }
}
