import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfigService} from "../config/config.service";
import {Router} from "@angular/router";
import {ConfigSettings} from "../config/config.settings";
import {CartService} from '../config/cart.service';
import {WishlistService} from '../config/wishlist.service';
import {AuthService} from 'angularx-social-login';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {User} from '../models/user';
import {Store} from '../models/store';
import {UserService} from '../config/user.service';

declare var $: any;

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  reaon: string;
  requestReason: string;
  requestReasonText: string;
  lang: string;
  user: User;
  store: Store;
  confirmMessage:string;
  maxReturnQuantity: number;
  returnQuantity: number;
  quantityOptions: any[] = [];
  disableCancelSubmit = false;
  selectedAttribute: any[] = [];
  selectedOption: Number[] = [];
  
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<DialogComponent>,
      private configService: ConfigService,
      private router: Router,
      private snackBarRef: MatSnackBar,
      private configSettings: ConfigSettings,
      //private authService: AuthService,
	  private userService: UserService,
      private wishlistService: WishlistService,
      private cartService: CartService,
      protected localStorage: LocalStorage
  ) { }

  ngOnInit() {
    this.lang = this.configSettings.getLang();
    if(this.data.images) {
        let imageCount = this.data.images.length;
        $(document).ready(function() {
            $('.slider-gallery').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                fade: true,
                responsive: true,
                asNavFor: '.thumb',
                prevArrow: '<span class="slick-prev" style=""><span class="slick-prev-icon"></span></span>',
                nextArrow: '<span class="slick-next" style=""><span class="slick-next-icon"></span></span>'
            });
            $('.thumb').slick({
                infinite: false,
                slidesToShow: 5,
                slidesToScroll: 1,
                asNavFor: '.slider-gallery',
                arrows: true,
                dots: false,
                vertical: true,
                focusOnSelect: true,
                prevArrow: '<span class="slick-prev" style=""><span class="slick-prev-icon"></span></span>',
                nextArrow: '<span class="slick-next" style=""><span class="slick-next-icon"></span></span>'
            });
        });
    }

    if(this.data.user){
        this.user = this.data.user;
    }

    /*
    * Order item return quantity
    * */
    if(this.maxReturnQuantity) {
        this.returnQuantity = this.maxReturnQuantity;
        for (let i = 1; i <= this.returnQuantity; i++) {
            this.quantityOptions.push({value: i, label: i});
        }
    }
  }

  closeDialog(data) {
      this.dialogRef.close(data);
  }
   addToBag(store, id, product_id) {
   //console.log(this.item);
		 this.configSettings.toggleLoading(true);
		const getParams = {
			user_id: id,
			lang: this.lang,
			store: store
		};
		const postParams = {
		  product_id: product_id,
		  user_id: id,
		  wishlist_product_id: product_id
		};
		console.log(postParams);
		this.configService.postRequest('move-wishlist-to-cart', getParams, postParams)
            .subscribe(
			response => {
          if (response.status === 200) {
            let items = response.data;
            this.userService.setWishlist(items);
			 this.dialogRef.close();
			 $("#remove-wishlist-"+product_id).remove();
			 var count = $(".wishlist-badge").html();
			 $(".wishlist-badge").html(count-1);
          }

          this.configSettings.toggleLoading(false);
        }, error => { this.configSettings.toggleLoading(false); });
	
	}

    cancelOrder() {
      this.disableCancelSubmit = true;
        this.configSettings.toggleLoading(true);
        const getParams = {
            id: this.data.id,
            user: this.data.user
        };

        const postParams = {
            reason: this.reaon,
            order_id: this.data.id,
            user_id: this.data.user
        };

        this.configService.postRequest('cancel-order', getParams, postParams)
            .subscribe(response => {console.log(response);
                this.configSettings.toggleLoading(false);
                this.dialogRef.close();
                if (response.status !== 200) {
                    let snackBarRef = this.snackBarRef.open(response.message, null, {
                        duration: 5000
                    });
                } else { 
                    this.router.navigate(['/account/my-orders']);  
                }

            }, error => { this.configSettings.toggleLoading(false); });
    }

    closeModal() {
      this.dialogRef.close();
    }

    logout() {
        /*if(this.user.is_social_register){
            this.authService.signOut();
        }*/
        this.wishlistService.changWishlistCount(0);
        this.cartService.changCartCount(0);
        this.localStorage.removeItem('userInfo').subscribe(() => {
            window.location.href = '/';
        });
    }

    submitReturnReason() {
      const reason = (this.requestReasonText) ? this.requestReasonText : this.requestReason;
        this.dialogRef.close({ data: {reason: reason, quantity: this.returnQuantity} });
    }

}
