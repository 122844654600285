import {EventEmitter, Injectable, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {LocalStorage} from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  private top_category = new Subject<any>();
  currentTopCategory = this.top_category.asObservable();

  private support_details = new Subject<any>();
  currentSupportDetails = this.support_details.asObservable();

  navigationStatus = true;
  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

  website_header_text = '';
  @Output() getWebsiteHeaderText: EventEmitter<string> = new EventEmitter<string>();

  constructor(protected localStorage: LocalStorage) { }

  toggleMainNavigation(key: boolean) {
    this.navigationStatus = key;
    this.change.emit(this.navigationStatus);
  }

  setWebsiteHeaderText(key: string) {
    this.website_header_text = key;
    this.getWebsiteHeaderText.emit(this.website_header_text);
  }

  setTopCategory(data: any) {
    this.top_category.next(data);
    window.localStorage.setItem('top-category', data);
  }

  setSupportDetails(data: any) {
    this.support_details.next(data);
    this.localStorage.setItem('support_details', data).subscribe(() => {});
  }
}
