import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClient, HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
//import {ProductItemComponent} from "../product-item/product-item.component";
import {intersectionObserverPreset, LazyLoadImageModule} from "ng-lazyload-image";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
//import {NgxTextOverflowClampModule} from "ngx-text-overflow-clamp/dist";
import {LoadingComponent} from "../loading/loading.component";
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {SideUserActionsComponent} from "../side-user-actions/side-user-actions.component";
import {HeaderUserActionsComponent} from "../header-user-actions/header-user-actions.component";
import {CRYPT_CONFIG_PROVIDER, CryptConfigProvider, EncryptionServiceModule} from "angular-encryption-service";
import {ConfigSettings} from "../config/config.settings";
import {DateFormatPipe} from "../config/DateFormatPipe";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HttpModule} from '@angular/http';
//import {AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule} from "angularx-social-login";
//import {MatDialogModule} from "@angular/material/dialog";
//import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

const AppCryptConfigProvider: CryptConfigProvider = {
    getSalt(): Promise<string> {
        return Promise.resolve(ConfigSettings.ENCRYPT_KEY);
    }
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpModule,
        HttpClientJsonpModule,
        RouterModule,
        EncryptionServiceModule.forRoot(),
        LazyLoadImageModule.forRoot({
            preset: intersectionObserverPreset
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        //NgxTextOverflowClampModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        //MatDialogModule,
        //SocialLoginModule,
        //BrowserAnimationsModule
    ],
    declarations: [
        //ProductItemComponent,
        LoadingComponent,
        SideUserActionsComponent,
        HeaderUserActionsComponent,
        DateFormatPipe
    ],
    exports: [
        //ProductItemComponent,
        LoadingComponent,
        SideUserActionsComponent,
        HeaderUserActionsComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LazyLoadImageModule,
        TranslateModule,
        //NgxTextOverflowClampModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        //MatDialogModule,
        //SocialLoginModule,
        //BrowserAnimationsModule,
        RouterModule
    ],
    providers: [
        {provide: CRYPT_CONFIG_PROVIDER, useValue: AppCryptConfigProvider},
        /*{
            provide: AuthServiceConfig,
            useFactory: provideConfig
        },*/
        DateFormatPipe
    ]
})

export  class SharedModule {
    constructor(
        translate: TranslateService,
        configSettings: ConfigSettings
    ) {
        let userLang = configSettings.getLang();
        translate.setDefaultLang('en');
        translate.use(userLang);
    }
}
