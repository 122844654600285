export class User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  dob: string;
  gender: string;
  image: string;
  phone_code: string;
  phone: string;
  code: string;
  is_phone_verified: boolean;
  is_email_verified: boolean;
  is_social_register: boolean;
  social_register_type: string;
  wallet_amount: string;
}
