import {NgModule} from "@angular/core";
import {SaveToLookbookComponent} from "./save-to-lookbook.component";
import {SharedModule} from "../shared/shared.module";
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from "@angular/material/dialog";
import {NgSelectModule} from "@ng-select/ng-select";

@NgModule({
    declarations: [
        SaveToLookbookComponent
    ],
    imports: [
        SharedModule,
        MatDialogModule,
        NgSelectModule
    ],
    entryComponents: [
        SaveToLookbookComponent
    ],
    exports: [
        SaveToLookbookComponent
    ],
    providers: [
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}}
    ]
})

export class SaveToLookbookModule {
    
}