import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "../shared/shared.module";
import {AddedToCartDialogComponent} from "./added-to-cart-dialog.component";
import {SlickModule} from "ngx-slick";
import {ProductItemModule} from "../product-item/product-item.module";
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from "@angular/material/dialog";
import {CRYPT_CONFIG_PROVIDER} from "angular-encryption-service";
import {AuthServiceConfig} from "angularx-social-login";

@NgModule({
  declarations: [
    AddedToCartDialogComponent
  ],
  imports: [
    SharedModule,
    MatDialogModule,
    ProductItemModule,
    SlickModule.forRoot()
  ],
  entryComponents: [
    AddedToCartDialogComponent
  ],
  exports: [
    AddedToCartDialogComponent
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
  ],
})
export class AddedToCartDialogModule { }
